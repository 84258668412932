import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { FaAngleDown } from "react-icons/fa6";

export const SingleInstitutionProjects = () => {
  const { id } = useParams();
  const [projects, setProjects] = useState([]);
  const [projectLoading, setProjectLoading] = useState(true);
  const [institution, setInstitution] = useState([]);
  const [institutionLoading, setInstitutionLoading] = useState(true);
  const [filteredProjects, setFilteredProjects] = useState([]);

  useEffect(() => {
    const fetchInstitution = async () => {
      try {
        const response = await axios.get(
          `https://admin.bwenge.com/api/institutions/${id}/`
        );
        setInstitutionLoading(false);
        if (response) {
          setInstitution(response.data);
        }
      } catch (error) {
        setProjectLoading(false);
      }
    };

    fetchInstitution();
  }, [id]);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axios.get(
          "https://admin.bwenge.com/api/projects/"
        );
        setProjectLoading(false);
        if (response) {
          const filteredProjects = response.data.filter(
            (project) =>
              project.institution && project.institution === parseInt(id)
          );
          setProjects(filteredProjects);
        }
      } catch (error) {
        setProjectLoading(false);
      }
    };

    fetchProjects();
  }, [id]);

  useEffect(() => {
    setFilteredProjects(projects);
  }, [projects]);

  const handleSearch = (searchTerm) => {
    const filtered = projects.filter((project) =>
      project.topics.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredProjects(filtered);
  };

  return (
    <div className="container mt-5">
      <div className="mb-4 flex">
        <div className="bg-gray-200 px-3 font-medium flex items-center">
          <p>Topics</p>
        </div>
        <input
          type="text"
          placeholder="Search projects..."
          className="w-full px-4 py-3 border border-gray-300  focus:outline-none focus:ring-2 focus:ring-blue-500"
          onChange={(e) => handleSearch(e.target.value)}
        />
      </div>
      <div className="flex">
        <div className="w-[15%]">
          <div className="avatar">
            <div class="w-32 rounded-full">
              <img src={institution.logo} alt={institution.name} />
            </div>
          </div>

          <div className="mb-3">
            <h3 className="">{institution.name}</h3>
          </div>

          {/* <div className="my-3">
            <div className="text-lg">
              <div className="my-2">Bachelor's Degree</div>
              <div className="my-2">Master's Degree</div>
              <div className="my-2">PHD</div>
            </div>
          </div> */}
        </div>
        <div className="w-[85%]">
          <div className="ui raised segment">
            {projectLoading ? (
              <div className="flex justify-center w-[100%]">
                <span className="loading loading-bars loading-lg"></span>
              </div>
            ) : (
              <>
                {filteredProjects.length ? (
                  <div>
                    <table className="ui celled table">
                      <thead>
                        <tr>
                          <th>Title</th>
                          <th>Authors</th>
                          <th>Level</th>
                          <th>Submission Date</th>
                          <th>action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredProjects.map((project) => (
                          <tr key={project.id}>
                            <td>{project.topics}</td>
                            <td>{`${project.author.first_name} ${project.author.last_name}`}</td>
                            <td>{project.level}</td>
                            <td>
                              {new Date(
                                project.submitted_date
                              ).toLocaleDateString()}
                            </td>
                            <td>
                              <Link
                                to={`/project/${project.id}`}
                                className="no-underline"
                              >
                                View
                              </Link>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <div>No projects found</div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
