import React from "react";

const Titles = ({title}) => {
  return (
    <div>
      <h1 className="font-bold text-4xl sm:text-5xl md:text-6xl lg:text-7xl xl:text-8xl text-[#280f91] bg-yellow-300  rounded-full py-2 px-5 inline-block">
        {title}
      </h1>
    </div>
  );
};

export default Titles;
