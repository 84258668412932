import React, { useState } from "react";
import DOMPurify from "dompurify";
import { ChapterContentModal } from "../../Actions/ChapterContentModal";

const Chapter = ({ chapter, editChapter }) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <div className="accordion-item border rounded  mt-3 text-lg">
      <div
        className={`${
          isActive ? "bg-gray-100" : ""
        } accordion-title flex items-center justify-between w-full cursor-pointer hover:bg-gray-100 px-4 py-2 transation duration-400`}
        onClick={() => setIsActive(!isActive)}
      >
        <h5 className="font-medium text-lg ">{chapter.title}</h5>
        <div className=" text-xl">{isActive ? "-" : "+"}</div>
      </div>
      {isActive && (
        <div className="accordion-content py-3 px-3 ml-3 ">
          <div>
            {/* <p
              className="card-text truncate-text"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(content.description),
              }}
            /> */}
          </div>
          <div className="flex justify-end my-3">
            <ChapterContentModal
              chapterData={chapter}
              editChapter={editChapter}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Chapter;
