import React, { useState } from "react";
import DOMPurify from "dompurify";
import { MdAssignmentAdd } from "react-icons/md";
import { FaPlus } from "react-icons/fa6";
import { FaMinus } from "react-icons/fa6";
import { SubmitModal } from "./Actions/SubmitModal";

const Accordion = ({ content }) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <div className="accordion-item border rounded  mt-3 text-lg">
      <div
        className="accordion-title flex items-center justify-between w-full cursor-pointer hover:bg-gray-200 px-4 py-3 transation duration-400"
        onClick={() => setIsActive(!isActive)}
      >
        <div className="flex my-2">
          <div className="border rounded flex items-center justify-center w-20">
            <MdAssignmentAdd size={25} />{" "}
          </div>
          <div className="px-3 py-1 my-2">
            <div>Assignment</div>
            <div className="font-semibold">{content.title}</div>
          </div>
        </div>
        {/* <h5 className="font-semibold">{content.title}</h5> */}
        <div className=" text-xl">
          {isActive ? (
            <div>
              <FaMinus />
            </div>
          ) : (
            <div>
              <FaPlus />
            </div>
          )}
        </div>
      </div>
      {isActive && (
        <div className=" p-4 m-3 bg-[#f9f9f9] rounded ">
          <div>
            <p
              className="card-text truncate-text"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(content.description),
              }}
            />
          </div>
          <div className="flex justify-between items-center ">
            <div className="font-semibold ">
              Due Date: {new Date(content.due_date).toLocaleDateString()}
            </div>
            <div className="my-3">
              <SubmitModal assignment={content}/>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Accordion;
