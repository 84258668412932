import React from "react";
import { useNavigate } from "react-router-dom";

export const GoBack = () => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  return (
    <div>
      <button onClick={goBack} className="btn bg-primary text-white">
        Go Back
      </button>
    </div>
  );
};
