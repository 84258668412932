import React, { useState, useEffect, useRef } from "react";
import { FaPlus } from "react-icons/fa";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import QuillEditor from "../QuillEditor";
import axios from "../../helpers/axios";
import { useForm } from "react-hook-form";
import { createCourseSchema } from "../validations/courseSchema";
import { yupResolver } from "@hookform/resolvers/yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MdEdit } from "react-icons/md";
import { IoMdAdd } from "react-icons/io";
import { IoMdRemove } from "react-icons/io";

const CreateCourseModal = ({ addCourse, auth }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showArticleModal, setShowArticleModal] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [step, setStep] = useState(1);
  const [chapters, setChapters] = useState([]);
  const [removedIds, setRemovedIds] = useState([]);
  const [course, setCourse] = useState();

  useEffect(() => {
    const savedUserInfo = localStorage.getItem("userInfo");
    if (savedUserInfo) {
      setUserInfo(JSON.parse(savedUserInfo));
    }
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(createCourseSchema),
  });
  const initialState = {
    title: "",
    description: "",
    teacher: "",
    course_image: null,
    course_type: "mooc",
  };
  const [coursesData, setCoursesData] = useState(initialState);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCoursesData({ ...coursesData, [name]: value });
  };

  const handleQuillChange = (value) => {
    setCoursesData({ ...coursesData, description: value });
  };

  const handleImageChange = (e) => {
    const { name, files } = e.target;
    setCoursesData({ ...coursesData, [name]: files[0] });
  };
  // console.log('userInfo', userInfo)
  const onSubmit = async (data) => {
    // console.log("course data", coursesData);
    const formData = new FormData();
    formData.append("title", data.title);
    formData.append("description", coursesData.description);
    formData.append("teacher", userInfo.userId);
    formData.append("course_type", coursesData.course_type);

    if (coursesData.course_image) {
      formData.append(
        "course_image",
        coursesData.course_image,
        coursesData.course_image.name
      );
    }
    setLoading(true);
    try {
      axios
        .post("/courses/create/", formData)
        .then((res) => {
          // console.log("res", res);
          if (res?.data) {
            setLoading(false);
            toast.success("Course created successfully");
            setCoursesData(initialState);
            setCourse(res?.data);
            setStep(2);
          } else {
            setLoading(false);
            toast.error("Error while create course, try later");
          }
        })
        .catch((error) => {
          setLoading(false);
          toast.error("Error while create course, try later");
        });
    } catch (error) {
      toast.error("Error while create course, try later");
      setLoading(false);
    }
  };
  // console.log("userinfo:", Number(userInfo?.userId));

  // for chapters

  const handleAddTag = () => {
    setChapters([...chapters, { title: "" }]);
  };

  const handleChangeOptional = (index, field, value) => {
    const newChapters = [...chapters];
    newChapters[index][field] = value;
    setChapters(newChapters);
  };

  const handleRemoveTag = (index) => {
    const newChapters = [...chapters];
    const removedOption = newChapters.splice(index, 1)[0];
    if (removedOption.id) {
      setRemovedIds([...removedIds, removedOption.id]);
    }
    setChapters(newChapters);
  };

  const handleSubmitChapter = async (e) => {
    setLoading(true);
    // console.log("get here: ", course);
    let successfulRequests = 0;
    let failedRequests = 0;
    try {
      if (chapters.length) {
        const chapterPromises = chapters.map(async (chapter) => {
          const data = {
            title: chapter.title,
          };

          try {
            const res = await axios.post(
              `/course/${course.id}/chapters/create/`,
              data,
              {
                headers: {
                  "Content-Type": "application/json",
                },
              }
            );
            if (res) {
              successfulRequests += 1;
            } else {
              failedRequests += 1;
            }
          } catch (error) {
            setLoading(false);
            console.log("Error occurred while create chapter:", error);
            toast.error("Failed to create chapter");
          }
        });

        await Promise.all(chapterPromises);
        setLoading(false);
        // console.log("successfulRequests: ", successfulRequests);
        // console.log("failedRequests: ", failedRequests);
        if (failedRequests === 0) {
          toast.success(
            "Chapters created. Please wait for admin approve to be visable to all users."
          );
          setChapters([]);
          setStep(1);
          addCourse(course);

          setIsOpen(false);
        } else {
          toast.error(`${failedRequests} chapter(s) failed to create`);
        }
      } else {
        setLoading(false);
        toast.error("Please add chapter title");
      }
    } catch (error) {
      setLoading(false);
      console.log("Error occurred while creating chapter:", error);
    }
  };
  return (
    <div className="">
      <button
        className="px-4 py-2 bg-[#198754] text-white rounded-md flex items-center"
        onClick={() => setIsOpen(true)}
      >
        <div>
          <FaPlus />
        </div>
        <div className="ml-2">Create Course</div>
      </button>

      {isOpen && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-md shadow-md w-[90%] md:w-[50%] max-h-[95vh] overflow-y-auto ">
            <div className="flex justify-between items-center">
              {step === 1 ? (
                <h2 className="text-2xl font-semibold">Create course</h2>
              ) : (
                <h2 className="text-2xl font-semibold">
                  Create course chapter
                </h2>
              )}
              <button
                className="text-gray-600 hover:text-gray-900"
                onClick={() => setIsOpen(false)}
              >
                <svg
                  className="h-6 w-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            {step === 1 && (
              <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
                <div className="mt-4">
                  <div>
                    <label
                      htmlFor="title"
                      className="block text-sm font-medium text-gray-700 my-2"
                    >
                      Title
                    </label>
                    <input
                      type="text"
                      name="title"
                      id="title"
                      onChange={handleInputChange}
                      className="input input-bordered w-full"
                      defaultValue={coursesData.title}
                      {...register("title")}
                    />
                    {errors.title && (
                      <span className="errorMsg text-danger text-sm">
                        {errors.title?.message}
                      </span>
                    )}
                  </div>
                  <div className="my-2">
                    <label
                      htmlFor="description"
                      className="block text-sm font-medium text-gray-700 my-2"
                    >
                      Description
                    </label>
                    <QuillEditor
                      value={coursesData.description}
                      onChange={handleQuillChange}
                    />
                  </div>
                  <div>
                    {auth?.role === "user" || userInfo?.role === "user" ? (
                      <div>
                        <input
                          type="hidden"
                          name="course_type"
                          onChange={handleInputChange}
                          className="input input-bordered w-full"
                          value="mooc"
                        />
                      </div>
                    ) : auth?.role === "institution_admin" ||
                      userInfo?.role === "institution_admin" ? (
                      <div>
                        <label
                          htmlFor="description"
                          className="block text-sm font-medium text-gray-700 my-2"
                        >
                          Course Type
                        </label>
                        <select
                          name="course_type"
                          id="course_type"
                          value={coursesData.course_type}
                          className="select select-bordered w-full"
                          onChange={handleInputChange}
                        >
                          <option value="">Select an option</option>
                          <option value="mooc">Mooc</option>
                          <option value="spoc"> Spoc</option>
                        </select>
                      </div>
                    ) : (
                      <div>no data</div>
                    )}
                  </div>
                  <div className="my-2">
                    <label
                      htmlFor="course_image"
                      className="block text-sm font-medium text-gray-700 my-2"
                    >
                      Course Image
                    </label>
                    <input
                      type="file"
                      name="course_image"
                      id="course_image"
                       accept="image/*"
                      onChange={handleImageChange}
                      className="file-input file-input-bordered file-input-md w-full"
                    />
                  </div>
                  {/* <div className="my-2">
                  <label
                    htmlFor="teacher"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Teacher
                  </label>
                  <input
                    type="number"
                    name="teacher"
                    id="teacher"
                    onChange={handleInputChange}
                    className="input input-bordered w-full"
                    defaultValue={coursesData.teacher}
                    {...register("teacher")}
                  />
                  {errors.title && (
                    <span className="errorMsg text-danger text-sm">
                      {errors.teacher?.message}
                    </span>
                  )}
                </div> */}
                  {/* To be done in future */}
                </div>
                <div className="mt-6 flex justify-end">
                  <button
                    className="px-4 py-2 bg-gray-600 text-white rounded-md"
                    onClick={() => setIsOpen(false)}
                  >
                    Close
                  </button>
                  <button
                    type="submit"
                    className="flex items-center ml-2 px-4 py-2 bg-blue-600 text-white rounded-md"
                    disabled={loading}
                  >
                    {loading && (
                      <span class="loading loading-spinner loading-sm mr-2"></span>
                    )}{" "}
                    Create Course
                  </button>
                </div>
              </form>
            )}

            {step === 2 && (
              <form>
                <div className="my-2">
                  <div className="mt-4">
                    <h6 class="mb-2 font-semibold tex-lg capitalize">
                      {" "}
                      Add chapter titles *
                    </h6>
                    <div>
                      {chapters.map((option, index) => (
                        <div
                          key={index}
                          className="flex items-center mt-3 w-full   h-12"
                        >
                          <div className="w-full">
                            <input
                              type="text"
                              id="title"
                              value={option.title}
                              onChange={(e) =>
                                handleChangeOptional(
                                  index,
                                  "title",
                                  e.target.value
                                )
                              }
                              placeholder="Enter chapter title"
                              className="input input-bordered w-full "
                            />
                          </div>
                          <div
                            onClick={() => handleRemoveTag(index)}
                            className="border p-2 rounded-lg hover:opacity-75 ml-2 cursol-pointer"
                          >
                            <IoMdRemove />
                          </div>
                        </div>
                      ))}
                      <button
                        type="button"
                        className="bg-gray-400 hover:bg-gray-300 rounded py-1 px-2  flex items-center text-sm mt-2 transaction duration-400"
                        onClick={() => handleAddTag()}
                      >
                        <div className="mr-1">
                          <IoMdAdd />
                        </div>
                        <div>Add More</div>
                      </button>
                    </div>
                  </div>
                  <div className="mt-6 flex justify-end">
                    <button
                      className="px-4 py-2 bg-gray-600 text-white rounded-md"
                      onClick={() => setIsOpen(false)}
                    >
                      Close
                    </button>
                    <button
                      type="submit"
                      onClick={() => handleSubmitChapter()}
                      className="flex items-center ml-2 px-4 py-2 bg-blue-600 text-white rounded-md"
                      disabled={loading}
                    >
                      {loading && (
                        <span class="loading loading-spinner loading-sm mr-2"></span>
                      )}{" "}
                      Create Chapter
                    </button>
                  </div>
                </div>
              </form>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default CreateCourseModal;
