import React from 'react';

function NotFound() {
return (
<div>
    <h2>Error 404: Page Not Found</h2>
</div>
);
}

export default NotFound;
