import React, { useEffect } from 'react';


function ShortCourse() {
useEffect(() => {
document.title = "Short Courses"
})
return (
<div>
    <h2>ShortCourse</h2>
</div>
);
}

export default ShortCourse;