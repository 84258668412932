import React, { useEffect } from "react";
import "./../styles/about.css";
import imageabout from "./../../imgs/aboutuspic.PNG";
const About = () => {
  return (
    <div className="">
      <div className="">
        <img src={imageabout} className=" imageabout" alt="" />
      </div>
      <div></div>
      <div className=" flex items-center container my-16 ">
        <div className=" w-1/4 flex justify-end">
          <div>
            <h1 className="storyabout text-center"> Dushakashake Dushakira U’Rwanda</h1>
            {/* <h3 className="aboutstart">Why we started it?</h3> */}
          </div>
        </div>

        <div className="px-8 ">
          <div className="text-start aboutparagraph text-xl">
            <p>
              To comprehensively open the channels for research and learning
              information dissemination in Rwanda, the BWENGE platform, owned by
              BWENGE Research and Learning Center Limited (BWENGE R.L.C Ltd),
              aims to achieve the mutual goal of using research and innovation
              as key drivers for the development of our country. We are devoted
              to encouraging the creation and sharing of knowledge from
              secondary schools to higher learning and research institutions,
              including the Diaspora, to enhance wisdom for future generations.
            </p>
            <p>
              The platform is designed to provide professional services with
              unified search and navigation of Rwandan academic literature, the
              best projects from Rwandan universities and colleges (project
              database), BWENGE MOOC (Massive Open Online Courses), and BWENGE
              SPORC (Small Private Online Courses), as well as national courses
              and training programs. Furthermore, the forum stimulates the
              future generations wisdom more accurate, systematic, and
              completely explicit and implicit knowledge embedded in the
              specific process of work, creativity and learning.
            </p>
            <p>
              We extend our gratitude to the platform users, including Rwandan
              private and governmental universities, companies, and other
              research institutions, who use this platform to strengthen the
              forces underlying our success.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default About;
