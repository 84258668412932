import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "../../helpers/axios";
import { ToastContainer, toast } from "react-toastify";

export const ConfirmPassword = () => {
  const { uid, token } = useParams();
  const navigate = useNavigate();

  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [loading, setLoading] = React.useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = React.useState("");
  const [validCredentials, setValidCredentials] = React.useState(false);

  useEffect(() => {
    const checkCredentials = async () => {
      try {
        await axios
          .get(`/auth/password-reset-confirm/${uid}/${token}/`)
          .then((response) => {
            setLoading(false);
            if (response.data) {
              setValidCredentials(true);
            } else {
              toast.error("Password reset link has expired or is invalid.");
              setTimeout(() => navigate("/login"), 5000);
            }
          })
          .catch((error) => {
            toast.error("Password reset link has expired or is invalid.");
            setTimeout(() => navigate("/login"), 5000);
          });
      } catch (error) {
        toast.error("Password reset link has expired or is invalid.");
        setTimeout(() => navigate("/login"), 5000);
      }
    };

    checkCredentials();
  }, [uid, token, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!password) {
      setError("Password is required");
      return;
    }
    if (password.length < 6) {
      setError("Password must be at least 6 characters long");
      return;
    }
    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }
    setIsSubmitting(true);

    try {
      const formData = new FormData();
      formData.append("password", password);
      formData.append("confirm_password", confirmPassword);
      formData.append("token", token);
      formData.append("uidb64", uid);

      axios
        .patch(`/auth/set-new-password/`, formData)
        .then((response) => {
          setIsSubmitting(false);
          if (response) {
            toast.success(response.data.message);
            setTimeout(() => navigate("/login"), 3000);
          } else {
            toast.error("Failed to reset password. Please try again. 1");
          }
        })
        .catch((error) => {
          setIsSubmitting(false);
          setError("Failed to reset password. Please try again. 2");
        });
    } catch (error) {
      setIsSubmitting(false);
      setError("Failed to reset password. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  if (loading) {
    return (
      <div class="flex justify-center w-[100%] my-3">
        <span class="loading loading-bars loading-lg"></span>
      </div>
    );
  }

  if (!validCredentials) {
    return (
      <div className="container flex items-center justify-center h-screen bg-gray-100">
        <div className="bg-white p-8 rounded-lg shadow-md text-center">
          <h2 className="text-2xl font-semibold mb-4 text-blue-600">
            Redirecting to login ...
          </h2>
          <h2 className="text-2xl font-semibold mb-4 text-blue-600">
            Password reset link has expired or is invalid
          </h2>

          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500 mx-auto"></div>
        </div>
      </div>
    );
  }

  return (
    <div className="container">
      <ToastContainer />
      <form
        onSubmit={handleSubmit}
        className="max-w-md mx-auto mt-8 p-6 bg-white rounded-lg shadow-md"
      >
        {/* Title */}
        <h2 className="text-2xl text-center font-semibold mb-4">
          Reset Password
        </h2>
        <div className="mb-4">
          <label
            htmlFor="password"
            className="block text-gray-700 text-sm font-bold mb-2"
          >
            New Password:
          </label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="confirmPassword"
            className="block text-gray-700 text-sm font-bold mb-2"
          >
            Confirm Password:
          </label>
          <input
            type="password"
            id="confirmPassword"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
        {error && <div className="mb-4 text-red-500 text-sm">{error}</div>}
        <button
          type="submit"
          disabled={isSubmitting}
          className="w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed"
        >
          {isSubmitting && (
            <span className="animate-pulse mr-2">
              <i className="fas fa-spinner fa-pulse"></i>
            </span>
          )}
          Reset Password
        </button>
      </form>
    </div>
  );
};
