import React, { useState } from "react";
import { Link } from "react-router-dom";
import thekomp from "./../../thekomp";
import axios from "../../helpers/axios";
import { ToastContainer, toast } from "react-toastify";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const forgotPasswordHandler = (e) => {
    e.preventDefault();
    if (!email) {
      setError("Email is required");
      return;
    }

    const formData = new FormData();
    formData.append("email", email);

    setLoading(true);
    setError("");
    axios
      .post("/auth/password-reset/", formData)
      .then((response) => {
        setLoading(false);
        toast.success(response?.data.message);
        // reset form
        setEmail("");
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.response.data.message);
      });
  };

  return (
    <div className="container my-10  flex justify-center">
      <ToastContainer />
      <div className="p-5 w-[95%] sm:w-[80%] md:w-[60%] max-auto shadow ">
        <h3 className="text-center">Forgot Password</h3>

        <form
          onSubmit={forgotPasswordHandler}
          className="register-screen__form"
        >
          <div>
            <div class="ui form warning">
              <div class="field">
                <label>Email:</label>
                <input
                  className="w-full"
                  type="email"
                  placeholder="Enter Your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>

              {error && <p className="text-red-500">{error}</p>}

              <div className="addiLogin">
                <p className="my-2">
                  No account?{" "}
                  <Link to="/signup">
                    <a href="#">Create one!</a>
                  </Link>
                </p>
              </div>
              <div class="ui warning message"></div>
              <button
                type="submit"
                className="cursor-pointer inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                disabled={loading}
              >
                {loading ? "Sending..." : "Send Email"}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;