import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import "animate.css";

import par1 from "./../../imgs/par1.png";

import mobile from "./../../imgs/mobile.PNG";
import message1 from "./../../imgs/message.PNG";
import address1 from "./../../imgs/address.PNG";
import { useNavigate } from "react-router-dom";

import hero_image from "../../imgs/svg/PICS-BWENGE.svg";
import course_image from "../../imgs/svg/PICS-BWENGE-2.svg";
import project_image from "../../imgs/svg/PICS-BWENGE-3.svg";
import community_image from "../../imgs/svg/PICS-BWENGE-4.svg";
import article_image from "../../imgs/svg/PICS-BWENGE-5.svg";
import "../styles/home.css";
import "../styles/animate.css";
import axios from "../../helpers/axios";
import { useFormatDate } from "../../hooks";
import Titles from "../Titles";
import { RiArrowRightSLine } from "react-icons/ri";
import { JoinUs } from "../JoinUs";
import { CourseCard } from "../CourseCard";
import { Slides } from "../Slides";
import { ArticleCard } from "../ArticleCard";

const Home = ({ auth }) => {
  const [articles, setArticles] = useState([]);
  const [isArticleLoading, setIsArticleLoading] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [projects, setProjects] = useState([]);
  const [courses, setCourses] = useState([]);
  const [coursesLoading, setCoursesLoading] = useState(true);
  const [userInfo, setUserInfo] = useState({});
  const [projectLoading, setProjectLoading] = useState(true);
  const navigate = useNavigate();

  const formatDate = useFormatDate();

  useEffect(() => {
    document.title = "Bwenge Courses";
  });

  useEffect(() => {
    const savedUserInfo = localStorage.getItem("userInfo");
    if (savedUserInfo) {
      setUserInfo(JSON.parse(savedUserInfo));
    }
  }, []);

  const sectionRefs = {
    hero: useRef(null),
    courses: useRef(null),
    projects: useRef(null),
    communities: useRef(null),
    articles: useRef(null),
  };

  useEffect(() => {
    if (articles.length > 0) {
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % articles.length);
      }, 3000);

      return () => clearInterval(interval);
    }
  }, [articles]);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axios.get("/projects/");
        setProjectLoading(false);
        if (response) {
          setProjects(response.data);
        }
      } catch (error) {
        setProjectLoading(false);
        console.error("Failed to fetch projects:", error);
      }
    };

    fetchProjects();
  }, []);

  const getArticles = () => {
    fetch("https://admin.bwenge.com/api/add-article/")
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setIsArticleLoading(false);
        setArticles(data);
      })
      .catch((error) => {
        setIsArticleLoading(false);
        console.error("Error fetching articles:", error);
      });
  };

  useEffect(() => {
    getArticles();
  }, []);

  useEffect(() => {
    axios
      .get("/courses/")
      .then((response) => {
        const filteredCourse = response.data.filter(
          (course) =>
            course.course_type.toLowerCase() === "regular" ||
            (course.course_type.toLowerCase() === "mooc" &&
              course.is_approved_status.toLowerCase() !== "awaiting approval")
        );
        setCourses(filteredCourse);
        setCoursesLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        setCoursesLoading(false);
      });
  }, []);

  const getRandomArticles = (articles) => {
    let randomArticles = [];
    for (let i = 0; i < 3; i++) {
      let index = Math.floor(Math.random() * articles.length);
      randomArticles.push(articles[index]);
      articles.splice(index, 1);
    }
    return randomArticles;
  };

  const truncateText = (text) => {
    if (text.length > 60) {
      return text.substring(0, 60) + "...";
    }
    return text;
  };

  useEffect(() => {
    // Observer for lazy loading images
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          // Check if the element is intersecting with the viewport
          if (entry.isIntersecting) {
            // Add animation classes to the intersecting element
            entry.target.classList.add(
              "animate__animated",
              "animate__slideInLeft"
            );
            // Stop observing the element once it has animated
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.1 } // Set the threshold for when the callback should be triggered
    );

    // Observe all section refs
    Object.values(sectionRefs).forEach((ref) => {
      if (ref.current) {
        observer.observe(ref.current);
      }
    });

    // Cleanup function to unobserve all sections when component unmounts
    return () => {
      Object.values(sectionRefs).forEach((ref) => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      });
    };
  }, []); // Empty dependency array means this effect runs once on mount

  return (
    <div className=" w-[97%] mx-auto">
      <section className="flex justify-center mt-10">
        <div className="min-h-[80vh] w-full flex flex-col lg:flex-row items-center">
          <div className="w-full lg:w-[60%] lg:pr-10 px-4 sm:px-6 md:px-8">
            <div ref={sectionRefs.hero}>
              <h1 className="sharePlat font-bold text-4xl sm:text-5xl md:text-6xl lg:text-7xl xl:text-8xl text-[#280f91] bg-yellow-300 rounded-full py-2 px-5 inline-block mb-4">
                Skills
              </h1>
              <div>
                <h1 className="my-3 font-bold text-4xl sm:text-5xl md:text-6xl lg:text-7xl xl:text-8xl text-[#1E84C6] sharePlat lg:whitespace-nowrap">
                  {" "}
                  Sharing Platform
                </h1>
                <p className="text-mainColor text-lg sm:text-xl md:text-2xl lg:text-3xl my-3 font-bold">
                  DUSHAKASHAKE DUSHAKIRA U RWANDA. Join BWENGE ​Platfrom TODAY.
                  Join as a researcher, learner or as an ​institution...
                </p>
              </div>
            </div>
            <div className="my-3 flex flex-col sm:flex-row md:gap-4">
              <Link
                to={"/login"}
                className="b bg-mainColor block w-full text-center text-white text-base sm:text-lg md:text-xl lg:text-2xl xl:text-3xl font-semibold rounded-xl px-4 py-2 no-underline hover:opacity-75"
              >
                Member
              </Link>
              <Link
                to={"/signup"}
                className="bg-mainColor block w-full text-center text-white text-base sm:text-lg md:text-xl lg:text-2xl xl:text-3xl font-semibold rounded-xl px-4 py-2 no-underline hover:opacity-75"
              >
                institution
              </Link>
            </div>
          </div>
          <div className="mt-8 lg:mt-5 h-full w-full lg:w-[40%]">
            <img
              src={hero_image}
              alt=""
              className="w-full h-full object-cover object-center"
              style={{ objectPosition: "left" }}
            />
          </div>
        </div>
      </section>

      <section className=" md:h-[100vh] mt-10 ">
        <div className="flex flex-col md:flex-row justify-between items-center h-full">
          <div className=" overflow-hidden h-full">
            <img
              src={course_image}
              alt=""
              srcset=""
              className="w-full h-full object-cover object-center"
              style={{ objectPosition: "right" }}
            />
          </div>
          <div className="w-full md:w-[70%] md:p-20">
            <h1
              className="text-mainColor font-semibold text-5xl md:text-7xl mt-5 md:mt-0"
              ref={sectionRefs.courses}
            >
              U<span className="font-bold">BWENGE</span> burarahurwa, ​Never
              stop learning!
            </h1>

            <div className="mt-5 flex items-center justify-between flex-col md:flex-row ">
              <div>
                <Titles title="Courses" />
              </div>
              <div>
                <Link
                  to={"/courses"}
                  className="text-mainColor font-semibold my-3 text-xl ml-3 bg-[#E0F8FF] rounded-lg px-5 py-2 no-underline flex items-center hover:opacity-75"
                >
                  <span>View All</span>
                  <RiArrowRightSLine />
                </Link>
              </div>
            </div>
            <div className="mt-5 w-[95%]">
              {coursesLoading ? (
                <div className="flex justify-center items-center">
                  <div className="animate-spin rounded-full h-10 w-10 border-t-2 border-b-2 border-primary"></div>
                </div>
              ) : (
                <>
                  {courses.length > 3 ? (
                    <Slides slidesToShow={3}>
                      {courses.map((course, index) => (
                        <CourseCard key={index} course={course} />
                      ))}
                    </Slides>
                  ) : (
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-2">
                      {courses.map((course, index) => (
                        <CourseCard key={index} course={course} />
                      ))}
                    </div>
                  )}
                </>
              )}
            </div>
            <div className="mt-5">
              <JoinUs title={"Join us TODAY"} />
            </div>
          </div>
        </div>
      </section>

      <section className="mt-10 ">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="w-full md:w-[40%] md:p-5">
            <h1
              ref={sectionRefs.projects}
              className="text-mainColor font-medium text-5xl md:text-6xl mt-5 md:mt-0"
            >
              Explore BWENGE \{" "}
              <span className="font-bold"> Project database</span>
            </h1>

            <div className="mt-5 flex items-center justify-between flex-col md:flex-row ">
              <div>
                <Titles title="Projects" />
              </div>
              {/* <div>
                <Link
                  to={"/courses"}
                  className="text-mainColor font-semibold text-xl ml-3 bg-[#E0F8FF] rounded-lg px-5 py-2 no-underline flex items-center hover:opacity-75"
                >
                  <span>View All</span>
                  <RiArrowRightSLine />
                </Link>
              </div> */}
            </div>
            <div className="mt-5 w-[95%] sm:w-full">
              {projectLoading ? (
                <div className="flex justify-center items-center">
                  <div className="animate-spin rounded-full h-10 w-10 border-t-2 border-b-2 border-primary"></div>
                </div>
              ) : (
                <>
                  {projects.length > 3 ? (
                    <Slides slidesToShow={2} rtl={true}>
                      {projects.map((project, index) => (
                        <div key={index}>
                          <Link
                            to={`/project/${project.id}`}
                            className="block no-underline text-black flex items-center font-semibold text-4xl p-10 text-center rounded bg-gray-200 hover:bg-gray-300 block m-2 flex items-center justify-center break-words h-56"
                          >
                            <span className="overflow-hidden overflow-ellipsis">
                              {project.topics.length > 25
                                ? project.topics.substring(0, 25) + "..."
                                : project.topics}
                            </span>
                          </Link>
                        </div>
                      ))}
                    </Slides>
                  ) : (
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
                      {projects.map((project, index) => (
                        <div key={index}>
                          <Link
                            to={`/project/${project.id}`}
                            className="block no-underline text-black flex items-center font-semibold text-4xl p-10 text-center rounded bg-gray-200 hover:bg-gray-300 block m-2 flex items-center justify-center break-words h-56"
                          >
                            <span className="overflow-hidden overflow-ellipsis">
                              {project.topics.length > 25
                                ? project.topics.substring(0, 25) + "..."
                                : project.topics}
                            </span>
                          </Link>
                        </div>
                      ))}
                    </div>
                  )}
                </>
              )}
            </div>
            <div className="mt-5">
              <JoinUs title={"Join us TODAY"} />
            </div>
          </div>
          <div className="mt-16">
            <img src={project_image} alt="" srcset="" />
          </div>
        </div>
      </section>

      <section className="mt-10 md:h-[100vh]">
        <div className="flex flex-col md:flex-row justify-between items-center h-full">
          <div className=" overflow-hidden h-full">
            <img
              src={community_image}
              alt=""
              className="w-full h-full object-cover object-center"
              style={{ objectPosition: "right" }}
            />
          </div>
          <div className="w-full md:w-[70%] md:p-5">
            <h1
              className="text-mainColor font-medium text-5xl md:text-6xl mt-5 md:mt-0"
              ref={sectionRefs.communities}
            >
              The best way to learn is to teach, <br />
              <span className="font-bold"> ​start sharing in communities.</span>
            </h1>

            <div className="mt-5 flex items-center justify-between flex-col md:flex-row ">
              <div>
                <Titles title="Communities" />
              </div>
              <div>
                <Link
                  to={"/communities"}
                  className="text-mainColor my-3 font-semibold text-xl ml-3 bg-[#E0F8FF] rounded-lg px-5 py-2 no-underline flex items-center hover:opacity-75"
                >
                  <span>View All</span>
                  <RiArrowRightSLine />
                </Link>
              </div>
            </div>
            <div className="mt-5">
              <div className=" flex flex-col md:flex-row  justify-center items-center">
                <div className="diaspora shadow font-semibold text-3xl p-16 text-center rounded bg-gray-200  block m-2 flex items-center justify-center break-words">
                  <span className="overflow-hidden overflow-ellipsis text-white">
                    Diaspora community
                  </span>
                </div>
                <div className="university shadow font-semibold text-3xl p-16 text-center rounded bg-gray-200  block m-2 flex items-center justify-center break-words">
                  <span className="overflow-hidden overflow-ellipsis text-black">
                    University community
                  </span>
                </div>
                <div className="department shadow font-semibold text-3xl p-16 text-center rounded bg-gray-200  block m-2 flex items-center justify-center break-words">
                  <span className="overflow-hidden overflow-ellipsis text-white">
                    Department community
                  </span>
                </div>
              </div>
            </div>
            <div className="mt-5">
              <JoinUs title={"Join us TODAY"} />
            </div>
          </div>
        </div>
      </section>

      <section className="">
        <div className="flex flex-col md:flex-row justify-between items-end">
          <div className="w-full md:w-[60%] md:p-5">
            <h1
              className="text-mainColor font-medium text-4xl md:text-6xl mt-5 md:mt-0 "
              ref={sectionRefs.articles}
            >
              <h1 className="font-bold text-3xl md:text-4xl text-mainColor bg-yellow-300  rounded-xl py-2 px-5 inline-block">
                TREND
              </h1>
              <span className="font-medium text-5xl">
                , know what is happening!
              </span>
            </h1>

            <div className=" flex items-center justify-between flex-col md:flex-row ">
              {/* <div>
                <Titles title="Communities" />
              </div> */}
              <div>
                <Link
                  to={"/articles"}
                  className="text-mainColor mb-3 font-medium text-lg ml-3 bg-[#E0F8FF] rounded-lg px-5 py-2 no-underline flex items-center hover:opacity-75"
                >
                  <span>View All</span>
                  <RiArrowRightSLine />
                </Link>
              </div>
            </div>
            <div className="w-[95%] sm:w-full">
              {isArticleLoading ? (
                <div className="flex justify-center items-center">
                  <div className="animate-spin rounded-full h-10 w-10 border-t-2 border-b-2 border-primary"></div>
                </div>
              ) : articles.length > 3 ? (
                <Slides slidesToShow={3} rtl={true}>
                  {articles.map((article, index) => (
                    <ArticleCard article={article} key={index} />
                  ))}
                </Slides>
              ) : (
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2">
                  {articles.map((article, index) => (
                    <ArticleCard article={article} key={index} />
                  ))}
                </div>
              )}
            </div>
          </div>
          <div className="mb-5 my-2">
            <img src={article_image} alt="" srcset="" />
          </div>
        </div>
      </section>

      <section className="mt-10 ">
        <h1 className="w-full py-4 bg-[#E0F8FF] text-center text-mainColor font-medium text-4xl md:text-5xl">
          OUR PARTNERS
        </h1>

        <div className="flex flex-col md:flex-row items-center justify-center gap-10 mt-10">
          <img src={par1} alt="partner image" />
          <img src={par1} alt="partner image" />
          <img src={par1} alt="partner image" />
          <img src={par1} alt="partner image" />
        </div>
      </section>

      <section className="mt-10">
        <div className="container mt-10">
          <div className="row">
            <div className="col-md-12">
              <h2 className="custom-title mb-4">CONTACT US</h2>
            </div>
          </div>

          <div className="row shadow-lg p-16 mb-5 bg-white rounded ">
            <div className="col-md-5">
              <div className="d-flex flex-column">
                <div className="d-flex mb-4">
                  <div className="mr-3">
                    <img src={address1} className="img-fluid" alt="Address" />
                  </div>
                  <div>
                    <h5 className="font-weight-bold">ADDRESS</h5>
                    <p>Kigali-Rwanda</p>
                  </div>
                </div>
                <div className="d-flex mb-4">
                  <div className="mr-3">
                    <img
                      src={mobile}
                      className="img-fluid"
                      alt="Phone Number"
                    />
                  </div>
                  <div>
                    <h5 className="font-weight-bold">PHONE NUMBER</h5>
                    <p>+250782843444</p>
                  </div>
                </div>
                <div className="d-flex mb-4">
                  <div className="mr-3">
                    <img src={message1} className="img-fluid" alt="Email" />
                  </div>
                  <div>
                    <h5 className="font-weight-bold">EMAIL</h5>
                    <p>bwengeorg@gmail.com</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-7">
              <form id="contact-form">
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Your Name"
                      required
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Your Email"
                      required
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Subject"
                      required
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <textarea
                      className="form-control"
                      rows="3"
                      placeholder="Message"
                      required
                    ></textarea>
                  </div>
                </div>
                <div className="  text-center text-md-left">
                  <button
                    type="submit"
                    className="bg-mainColor w-full py-3 text-white text-lg rounded hover:opacity-75 col-md-12"
                  >
                    Send Message
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
