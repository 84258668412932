import React, { useEffect } from 'react';


function LongCourse() {
useEffect(() => {
document.title = "Long Courses"
})
return (
<div>
    <h2>LongCourse</h2>
</div>
);
}

export default LongCourse;
