import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "../../helpers/axios";
import { FaUsers } from "react-icons/fa";
import twiyubake3 from "./../../imgs/projectpromoting.jpg";
import { Tab, TabList, Tabs, TabPanel } from "react-tabs";
import Description from "../Description";
import DOMPurify from "dompurify";
import Accordion from "../Accordion";
import { useNavigate } from "react-router-dom";
import { LoginModal } from "../LoginModal";
import { GoDotFill } from "react-icons/go";
import { toast } from "react-toastify";

export const SingleCourse = ({ auth }) => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [course, setCourse] = useState({
    chapters: [],
    course_image: "",
    course_type: "",
    description: "",
    enrollments: [],
    id: null,
    is_approved: false,
    is_approved_status: "",
    teacher: null,
    title: "",
  });
  const [courseLoading, setCourseLoading] = useState(false);
  const [assignmentLoading, setAssignmentLoading] = useState(false);
  const [assignments, setAssignments] = useState(false);
  const [quizzes, setQuizzes] = useState(false);
  const [quizLoading, setQuizLoading] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState();
  const [chapters, setChapters] = useState([]);
  const [showModal, setShowModal] = useState(false);

  // check login

  useEffect(() => {
    const savedUserInfo = localStorage.getItem("userInfo");
    if (savedUserInfo) {
      setUserInfo(JSON.parse(savedUserInfo));
    }
  }, [setUserInfo]);

  useEffect(() => {
    if (auth?.userEmail || userInfo?.userEmail) {
      setIsLogin(true);
    }
  }, [auth, userInfo]);

  useEffect(() => {
    setCourseLoading(true);
    axios
      .get(`/courses/${id}/`)
      .then((res) => {
        if (res.data) {
          // console.log('course: ', res.data)
          setCourseLoading(false);
          setCourse(res.data);
          setChapters(res?.data?.chapters);
        } else {
          setCourseLoading(false);
          console.log("error happen");
        }
      })
      .catch((error) => {
        // console.error("Error:", error);
        setCourseLoading(false);
      });
  }, []);
  // get assignments
  useEffect(() => {
    setAssignmentLoading(true);
    axios
      .get(`/assignments/`)
      .then((res) => {
        if (res.data) {
          setAssignmentLoading(false);
          setAssignments(res.data);
        } else {
          setAssignmentLoading(false);
          console.log("error happen");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setAssignmentLoading(false);
      });
  }, []);

  // get quizzes
  useEffect(() => {
    setQuizLoading(true);
    axios
      .get(`/quizzes/`)
      .then((res) => {
        if (res.data) {
          setQuizLoading(false);
          setQuizzes(res.data);
        } else {
          setQuizLoading(false);
          console.log("error happen");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setQuizLoading(false);
      });
  }, []);

  // handle redirect to course

  const handleCheckLogin = (courseId) => {
    if (isLogin) {
      const userId = userInfo.userId;
      // navigate(`/takecourse/${courseId}`);
      const isEnrolled = course.enrollments.some(
        (enrollment) => enrollment.user_id === userId
      );
      if (isEnrolled) {
        // navigate to the takecourse page
        navigate(`/takecourse/${courseId}`);
      } else {
        // handle API call to take the course
        takeCourseApi(courseId);
      }
    } else {
      setShowModal(true);
    }
  };

  const takeCourseApi = (courseId) => {
    const formData = new FormData();
    formData.append("user_id", userInfo.userId);
    formData.append("course", courseId);
    axios
      .post(`/courses/${courseId}/take/`, formData)
      .then((res) => {
        // console.log("res", res);
        if (res.data) {
          // redirect to the course page
          navigate(`/takecourse/${courseId}`);
        } else {
          toast.error("Fail to take course, Try later");
        }
      })
      .catch(() => {
        toast.error("Fail to take course, Try later");
        console.log("error");
      });
  };

  return (
    <div>
      {courseLoading ? (
        <div>
          <div class="flex justify-center w-[100%]">
            <span class="loading loading-bars loading-lg"></span>
          </div>
        </div>
      ) : (
        <div>
          <div className="bg-blue-50 border border-white">
            <div className="w-full max-w-[100%] sm:max-w-[540px] md:max-w-[720px] lg:max-w-[960px] xl:max-w-[1140px] 2xl:max-w-[1320px] mx-auto px-4 sm:px-6 lg:px-8 my-[50px]">
              <div className="h-[50vh] ">
                <div className="grid grid-cols-1 sm:grid-cols-2 items-center h-full ">
                  <div className="h-full mt-20 px-10">
                    <div>
                      <div className="border rounded-full px-3 py-1 inline-block">
                        {course.course_type}
                      </div>
                      <h1 className="mt-3 font-bold">{course.title}</h1>
                      <div className="mt-3">
                        <Description
                          description={course.description}
                          showMore={false}
                          maxLength={200}
                        />
                      </div>

                      <div>
                        <div className="flex items-center text-lg mt-3">
                          <span>
                            <FaUsers size={20} />
                          </span>
                          <span className="mx-2">
                            {" "}
                            {course.enrollments.length}
                          </span>
                          <span> Members </span>
                        </div>
                      </div>
                      <div className="centerX">
                        {!isLogin && showModal && (
                          <LoginModal
                            setIsLogin={setIsLogin}
                            onClose={() => setShowModal(false)}
                            content={"You need to Login to take this course"}
                          />
                        )}
                        <button
                          onClick={() => handleCheckLogin(course.id)}
                          className="bg-blue-700 px-3 py-2 text-white mt-3 rounded font-medium hover:bg-blue-600 text-lg"
                        >
                          Start Course
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className=" h-full flex items-start justify-center  ">
                    <img
                      src={course.course_image}
                      alt={course.title}
                      className=" rounded-xl w-[80%] h-[65%] object-cover"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className=" mt-16  w-full max-w-[100%] sm:max-w-[540px] md:max-w-[720px] lg:max-w-[960px] xl:max-w-[1140px] 2xl:max-w-[1320px] mx-auto px-4 sm:px-6 lg:px-8 my-[50px]">
            <Tabs>
              <TabList className={{ marginBottom: "30px" }}>
                <Tab>About Course</Tab>
                <Tab>Course Chapters</Tab>
                {/* <Tab>Assigments</Tab>
                <Tab>Quizzes</Tab> */}
              </TabList>
              <TabPanel>
                <div className="mt-4">
                  <h4 className="font-semibold">Description</h4>
                  <div className="mt-3">
                    <p
                      className="card-text truncate-text"
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(course?.description),
                      }}
                    />
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="mt-4">
                  <h4 className="font-semibold">Chapters</h4>
                  <div className="mt-3">
                    <div className=" h-[65vh] overflow-auto">
                      {courseLoading ? (
                        <div>loading...</div>
                      ) : (
                        <div>
                          {chapters.map((chapter, index) => (
                            <div key={index}>
                              <h5 className="border-b px-3 py-3 text-lg font-medium flex items-center">
                                <span className="font-bold text-xl">
                                  <GoDotFill />
                                </span>{" "}
                                <span className="font-medium text-xl">
                                  {chapter.title}
                                </span>
                              </h5>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </TabPanel>

              {/* <TabPanel>
                <div className="mt-5">
                  <h4 className="font-semibold">Assignments</h4>
                  {assignmentLoading ? (
                    <div class="flex justify-center w-[100%]">
                      <span class="loading loading-bars loading-lg"></span>
                    </div>
                  ) : (
                    <div>
                      {assignments.length ? (
                        <div>
                          {assignments.map((assignment, index) => (
                            <div key={index}>
                              <Accordion content={assignment} />
                            </div>
                          ))}
                        </div>
                      ) : (
                        <div>
                          <div>No assignments found</div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </TabPanel>
              <TabPanel>
                <div className="mt-5">
                  <h4 className="font-semibold">Quizzes</h4>

                  <div className="mt-3">
                    {quizLoading ? (
                      <div class="flex justify-center w-[100%]">
                        <span class="loading loading-bars loading-lg"></span>
                      </div>
                    ) : (
                      <div>
                        {quizzes.length ? (
                          <div>
                            {quizzes.map((quiz, index) => (
                              <div
                                key={index}
                                className="bg-gray-100 p-4 mt-3 rounded"
                              >
                                <h3>Start The {quiz.title} Quiz</h3>
                                <p>Good Luck!</p>
                                <button className="btn btn-primary">
                                  Start Quiz
                                </button>
                              </div>
                            ))}
                          </div>
                        ) : (
                          <div>No Quizzes found</div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </TabPanel> */}
            </Tabs>
          </div>
        </div>
      )}
    </div>
  );
};
