import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DOMPurify from "dompurify";
import { formatDate, truncateText } from "../utils";

export const ArticleCard = ({ article }) => {
  // console.log("article", article);
  return (
      <div className="mx-2">
        <div className="card">
          <img
            className="card-img-top"
            src={article.poster_image}
            alt="image"
          />
          <div className="card-body">
            <h5 className="card-title">
              <b>{article.title}</b>
            </h5>
            <p
              className="card-text custom-paragraph-style"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(truncateText(article.description), {
                  ALLOWED_TAGS: ['p'],
                  ALLOWED_ATTR: []
                }),
              }}
            />
            <div>
              <div className="button-container">
                <Link to={`/article/${article.id}`} className="readmore btn">
                  Read More
                </Link>
                <span className="date">{formatDate(article.date)}</span>
              </div>
              {/* <div className="icon-container">
                <img src={like} alt="icon" />
                <img src={eye} alt="icon" />
                <img src={chatting} alt="icon" />
              </div> */}
            </div>
          </div>
        </div>
      </div>
  );
};
