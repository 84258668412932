import React, { useEffect, useState } from "react";
import { FaFileAlt } from "react-icons/fa";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "../../../helpers/axios";
import "../../styles/course.css";
import { MdQuiz } from "react-icons/md";
import DOMPurify from "dompurify";
import { ToastContainer, toast } from "react-toastify";

export const TakeQuiz = () => {
  const { courseId, quizId } = useParams();
  const [courseLoading, setCourseLoading] = useState(false);
  const [course, setCourse] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [quiz, setQuiz] = useState({
    title: "",
    description: "",
    questions: [],
  });
  const [selectedChoices, setSelectedChoices] = useState({});
  const [currentIndex, setCurrentIndex] = useState(0);
  const [userInfo, setUserInfo] = useState({});
  const [result, setResult] = useState({
    correct_answers: "",
    score: "",
    total_questions: "",
  });

  const questionsPerPage = 5;
  let no = 1;
  const navigate = useNavigate();
  //   console.log("id:", lectureId);

  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    if (!userInfo) {
      navigate("/login");
      return;
    }
  }, []);

  // assin login data to state
  useEffect(() => {
    const savedUserInfo = localStorage.getItem("userInfo");
    if (savedUserInfo) {
      setUserInfo(JSON.parse(savedUserInfo));
    }
  }, []);
  const handleNext = () => {
    setCurrentIndex(currentIndex + questionsPerPage);
  };

  const handleBack = () => {
    setCurrentIndex(currentIndex - questionsPerPage);
  };

  const handleChoiceChange = (questionId, choiceId) => {
    setSelectedChoices({
      ...selectedChoices,
      [questionId]: choiceId,
    });
  };

  useEffect(() => {
    setCourseLoading(true);
    axios
      .get(`/quiz/${quizId}/`)
      .then((res) => {
        setCourseLoading(false);
        if (res.data) {
          setQuiz(res.data);
        } else {
          console.log("error happen");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setCourseLoading(false);
      });
  }, []);
  // console.log("quize", quiz);

  const handleSubmit = () => {
    setSubmitLoading(true);
    const allUnanswered = Object.keys(selectedChoices).every(
      (key) => selectedChoices[key] === undefined
    );

    if (allUnanswered) {
      setSubmitLoading(false);
      toast.error(
        "No questions answered. Please answer at least one question."
      );
      return; // Stop further execution
    }

    const formData = new FormData();
    const answers = Object.keys(selectedChoices).map((questionId) => ({
      question: parseInt(questionId),
      selected_choice: selectedChoices[questionId],
    }));

    answers.forEach((answer, index) => {
      formData.append(`answers[${index}][question]`, answer.question);
      formData.append(
        `answers[${index}][selected_choice]`,
        answer.selected_choice
      );
    });

    formData.append("user_id", userInfo.userId);

    axios
      .post(`/quizzes/${quiz?.id}/take/`, formData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setSubmitLoading(false);
        console.log("quiz result: ", response);
        if (response) {
          setResult(response.data);
          setSelectedChoices({});
          console.log("Success:", response.data);
          setIsOpen(true);
          // toast.success("complete quiz");
        } else {
          console.log("error happen");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  return (
    <div className="container my-10">
      <ToastContainer />
      {courseLoading ? (
        <div class="flex justify-center w-[100%]">
          <span class="loading loading-bars loading-lg"></span>
        </div>
      ) : (
        <div>
          <div className="flex my-2 ">
            <div className="border rounded flex items-center justify-center w-20">
              <MdQuiz size={25} />{" "}
            </div>
            <div className="px-3 py-1 my-2">
              <div className=" text-xl">Quiz</div>
              <div>
                <h2>{quiz.title ? quiz.title : ""}</h2>
              </div>
            </div>
          </div>
          <p
            className="card-text truncate-text text-xl"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(quiz.description),
            }}
          />
          {quiz.questions.length ? (
            <div>
              <div className="bg-gray-100 rounded-3xl p-10  pb-20 mt-3">
                {quiz.questions
                  .slice(currentIndex, currentIndex + questionsPerPage)
                  .map((question, index) => (
                    <div key={index} className="mt-4">
                      <h4>
                        <span>{no++}.</span> {question.text}
                      </h4>
                      <div>
                        {question.choices.map((choice) => (
                          <div
                            key={choice.id}
                            className="border-b border-white p-3 flex items-center"
                          >
                            <input
                              type="radio"
                              name={`question-${index}`}
                              value={choice.id}
                              id={`question-${question.id}-choice-${choice.id}`}
                              onChange={() =>
                                handleChoiceChange(question.id, choice.id)
                              }
                              checked={
                                selectedChoices[question.id] === choice.id
                              }
                            />
                            <label
                              htmlFor={`question-${question.id}-choice-${choice.id}`}
                              className="text-lg"
                            >
                              {choice.text}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                <div className="mt-4 flex justify-end">
                  {currentIndex > 0 && (
                    <button
                      onClick={handleBack}
                      className="btn btn-secondary mx-3"
                    >
                      Back
                    </button>
                  )}
                  {currentIndex + questionsPerPage < quiz.questions.length ? (
                    <button
                      onClick={handleNext}
                      className="btn btn-primary mx-3"
                    >
                      Next
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className="flex items-center ml-2 px-4 py-2 bg-blue-600 text-white rounded-md"
                      disabled={submitLoading}
                      onClick={handleSubmit}
                    >
                      {submitLoading && (
                        <span className="loading loading-spinner loading-sm mr-2"></span>
                      )}{" "}
                      Submit
                    </button>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div>{""}</div>
          )}
        </div>
      )}

      <div>
        {isOpen && (
          <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white p-6 rounded-md shadow-md w-[90%] md:w-[50%] ">
              <div className="flex justify-end">
                <button
                  className="text-gray-600 hover:text-gray-900"
                  onClick={() => setIsOpen(false)}
                >
                  <svg
                    className="h-6 w-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
              <h1 className="text-4xl font-bold text-green-600 mb-4 text-center">
                Congratulations!
              </h1>
              <p className="text-xl mb-6 text-center">
                You have achieved the following result:
              </p>
              <h1 className="text-5xl font-extrabold text-green-600 text-center">
                {result.score}%
              </h1>

              <p className="font-semibold text-center text-2xl">Score card:</p>

              <div className="flex justify-between">
                <div className="flex items-center flex-col">
                  <div className="w-16 h-16 rounded-full bg-green-300 text-white font-semibold text-2xl flex  items-center justify-center">
                    {result.correct_answers}
                  </div>
                  <p className="my-2 font-medium text-lg">Score Answers</p>
                </div>
                <div className="flex items-center flex-col">
                  <div className="w-16 h-16 rounded-full bg-blue-300 text-white font-semibold text-2xl flex  items-center justify-center">
                    {result.total_questions}
                  </div>
                  <p className="my-2 font-medium text-lg">Total Questions</p>
                </div>
                <div className="flex items-center flex-col">
                  <div className="w-16 h-16 rounded-full bg-red-300 text-white font-semibold text-2xl flex  items-center justify-center">
                    {result.total_questions - result.correct_answers}
                  </div>
                  <p className="my-2 font-medium text-lg">Wrong Answers</p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
