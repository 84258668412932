import React, { useState, useEffect } from "react";
import axios from "../../../helpers/axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FaChevronRight } from "react-icons/fa";
import { FaChevronDown } from "react-icons/fa";
import ChapterAccordion from "../../ChapterAccordion";
import Accordion from "../../Accordion";
import { ToastContainer, toast } from "react-toastify";

export const TakeCourse = ({ auth }) => {
  const { id } = useParams();
  const [courseLoading, setCourseLoading] = useState(true);
  const [course, setCourse] = useState([]);
  const [chapters, setChapters] = useState([]);
  const [assignments, setAssignments] = useState([]);
  const [assLoading, setAssLoading] = useState(true);
  const [quizzes, setQuizzes] = useState([]);
  const [quizLoading, setQuizLoading] = useState(true);
  const [description, setDescription] = useState(false);
  const [isAssignment, setIsAssignment] = useState(false);
  const [isChapter, setIsChapter] = useState(false);
  const [isQuiz, setIsQuiz] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    if (!userInfo) {
      navigate("/login");
      return;
    }
  }, []);
  
  useEffect(() => {
    axios
      .get(`/courses/${id}/`)
      .then((res) => {
        setCourseLoading(false);
        // console.log("course: ", res);
        if (res.data) {
          setCourse(res.data);
          setChapters(res?.data?.chapters);
        } else {
          console.log("error happen");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setCourseLoading(false);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`/assignments/`)
      .then((res) => {
        setAssLoading(false);
        if (res.data) {
          const filteredAssignments = res.data.filter(
            (assignment) => assignment.course.id === course.id
          );
          setAssignments(filteredAssignments);
        } else {
          console.log("error happen");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setCourseLoading(false);
      });
  }, [course.id]);

  useEffect(() => {
    axios
      .get(`/quizzes/`)
      .then((res) => {
        setQuizLoading(false);
        if (res.data) {
          const filteredQuizzes = res.data.filter(
            (quiz) => quiz.course.id === course.id
          );
          setQuizzes(filteredQuizzes);
          // console.log("filteredQuizzes: ", quizzes);
        } else {
          console.log("error happen");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setQuizLoading(false);
      });
  }, [course.id]);
  // console.log('chapters: ', chapters)
  return (
    <div>
      <ToastContainer />
      <div className="container my-10 ">
        {courseLoading ? (
          <div>
            <div class="flex justify-center w-[100%]">
              <span class="loading loading-bars loading-lg"></span>
            </div>
          </div>
        ) : (
          <div>
            <h1 className="font-bold mb-4">{course.title}</h1>
            {/* <div className="my-4">
          <img
            src={course.course_image}
            alt={course.name}
            srcset=""
            className="h-[300px]"
          />
        </div> */}
            {/* <p className="text-xl mb-3">{course.description}</p> */}
            <div className="bg-gray-100 rounded-3xl p-10  pb-20">
              <div className="mt-3 text-lg border-b pb-3">
                <div
                  className="accordion-title flex items-center w-full cursor-pointer hover:bg-gray-100 px-4 py-3 transation duration-400"
                  onClick={() => setDescription(!description)}
                >
                  <div className=" text-xl mr-4">
                    {description ? (
                      <div>
                        <FaChevronDown />
                      </div>
                    ) : (
                      <div>
                        <FaChevronRight />
                      </div>
                    )}
                  </div>
                  <h5 className="font-semibold text-[25px]">Course Overview</h5>
                </div>
                {description && (
                  <div className=" py-3 px-3  border rounded">
                    <p className="text-xl">somdmdkdkkwkke</p>
                  </div>
                )}
              </div>

              {chapters.length ? (
                <div>
                  {chapters.map((chapter, index) => (
                    <div>
                      <ChapterAccordion chapter={chapter} />
                    </div>
                  ))}
                </div>
              ) : (
                <div>{""}</div>
              )}

              <div className="mt-3 text-lg border-b pb-3">
                <div
                  className="accordion-title flex items-center w-full cursor-pointer hover:bg-gray-100 px-4 py-3 transation duration-400"
                  onClick={() => setIsAssignment(!isAssignment)}
                >
                  <div className=" text-xl mr-4">
                    {isAssignment ? (
                      <div>
                        <FaChevronDown />
                      </div>
                    ) : (
                      <div>
                        <FaChevronRight />
                      </div>
                    )}
                  </div>
                  <h5 className="font-semibold text-[25px]">Assignments</h5>
                </div>
                {isAssignment && (
                  <div className=" py-3 px-3  border rounded">
                    {assLoading ? (
                      <div class="flex justify-center w-[100%]">
                        <span class="loading loading-bars loading-lg"></span>
                      </div>
                    ) : (
                      <div>
                        {assignments.length ? (
                          <div>
                            {assignments.map((assignment, index) => (
                              <div key={index}>
                                <Accordion content={assignment} />
                              </div>
                            ))}
                          </div>
                        ) : (
                          <div>
                            <div>No assignments found</div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div className="mt-3 text-lg border-b pb-3">
                <div
                  className="accordion-title flex items-center w-full cursor-pointer hover:bg-gray-100 px-4 py-3 transation duration-400"
                  onClick={() => setIsQuiz(!isQuiz)}
                >
                  <div className=" text-xl mr-4">
                    {isQuiz ? (
                      <div>
                        <FaChevronDown />
                      </div>
                    ) : (
                      <div>
                        <FaChevronRight />
                      </div>
                    )}
                  </div>
                  <h5 className="font-semibold text-[25px]"> Quizzes</h5>
                </div>
                {isQuiz && (
                  <div className=" py-3 px-3  border rounded">
                    {quizLoading ? (
                      <div class="flex justify-center w-[100%]">
                        <span class="loading loading-bars loading-lg"></span>
                      </div>
                    ) : (
                      <div>
                        {quizzes.length ? (
                          <div>
                            {quizzes.map((quiz, index) => (
                              <div
                                key={index}
                                className="bg-gray-100 p-4 mt-3 rounded border"
                              >
                                <h3>
                                  Start The{" "}
                                  <span className="font-bold capitalize">
                                    {quiz.title}
                                  </span>{" "}
                                  Quiz
                                </h3>
                                <p>Good Luck!</p>
                                <Link
                                  to={`takeQuiz/${quiz.id}`}
                                  className="btn btn-primary"
                                >
                                  Start Quiz
                                </Link>
                              </div>
                            ))}
                          </div>
                        ) : (
                          <div>No Quizzes found</div>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
