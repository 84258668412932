import { FiLogOut } from "react-icons/fi";
import userImage from "./../imgs/user.png";
import { Link } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { MdOutlineDashboard } from "react-icons/md";
import { CgProfile } from "react-icons/cg";
import axios from "../helpers/axios";

const AvatarSide = ({ auth, setAuth }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [loginModal, setLoginModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [userProfile, setUserProfile] = useState({});
  const navigate = useNavigate();
  const dropdownRef = useRef(null);

  const handleToggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("userInfo");
    setAuth(null);
    window.location.href = "/login";
    // navigate("/login");
  };

  useEffect(() => {
    const savedUserInfo = localStorage.getItem("userInfo");
    if (savedUserInfo) {
      setUserInfo(JSON.parse(savedUserInfo));
    }
  }, [setUserInfo]);

  useEffect(() => {
    if (userInfo.userId) {
      axios
        .get(`/auth/profile/${userInfo.userId}/`)
        .then((res) => {
          setLoading(false);
          if (res.data) {
            setUserProfile(res.data);
          } else {
            // setLoading(false);
            console.log("error happen");
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          setLoading(false);
        });
    }
  }, [userInfo]);

  const { fullName = "", profileImage = "" } = userInfo;
  return (
    <div className="flex  md:order-2">
      <div>
        {!auth && !userInfo.userEmail ? (
          <div className="mt-2 mx-4">
            <Link to="/login" className="custom-login-button">
              Login
            </Link>
            <Link to="/signup" className="custom-signup-button">
              <button type="button" onClick={(e) => setLoginModal(true)}>
                <span className="button-text">SignUp</span>
              </button>
            </Link>
          </div>
        ) : (
          <div className="flex items-center mx-4">
            {userInfo.role === "user" ? (
              <Link to="/account/home" className="user-name">
                <span className="">{auth?.fullName || fullName}</span>
              </Link>
            ) : userInfo.role === "institution_admin" ? (
              <Link to="/account/institution" className="user-name">
                <span className="">{auth?.fullName || fullName}</span>
              </Link>
            ) : (
              <Link to="/" className="user-name">
                <span className="">{auth?.fullName || fullName}</span>
              </Link>
            )}
            <li className=" nav-color  flex items-center ">
              <div className=" relative  flex items-center" ref={dropdownRef}>
                <a
                  className=""
                  href="#"
                  id="navbarDropdownMenuLink"
                  role="button"
                  onClick={handleToggleDropdown}
                  aria-expanded="false"
                >
                  {loading ? (
                    <div>
                      <span class="loading loading-spinner loading-sm mr-2"></span>
                    </div>
                  ) : (
                    <div class="avatar">
                      <div class="w-12 rounded-full">
                        <img src={userProfile.profile_picture} />
                      </div>
                    </div>
                  )}
                </a>
                <div
                  className={`shadow-lg mt-3 rounded bg-white text-black ${
                    dropdownOpen ? "show" : "hidden"
                  } `}
                  aria-labelledby="navbarDropdownMenuLink"
                  id="logout-head"
                >
                  {userInfo.role === "user" ? (
                    <Link
                      to="/account/home"
                      className="px-5 py-2 hover:bg-gray-100 cursor-pointer block no-underline text-black flex items-center"
                    >
                      <MdOutlineDashboard />{" "}
                      <div className="mx-2">Dashboard</div>
                    </Link>
                  ) : userInfo.role === "institution_admin" ? (
                    <Link
                      to="/account/institution"
                      className="px-5 py-2 hover:bg-gray-100 cursor-pointer block no-underline text-black flex items-center"
                    >
                      <MdOutlineDashboard />
                      <div className="mx-2">Dashboard</div>
                    </Link>
                  ) : (
                    <Link
                      to="/"
                      className="px-5 py-2 hover:bg-gray-100 cursor-pointer block no-underline text-black flex items-center"
                    >
                      <MdOutlineDashboard />
                      <div className="mx-2">Dashboard</div>
                    </Link>
                  )}
                  <div>
                    <Link
                      to="/profile"
                      className="px-5 py-2 hover:bg-gray-100 cursor-pointer block no-underline text-black flex items-center"
                    >
                      <CgProfile />
                      <div className="mx-2">Profile</div>
                    </Link>
                  </div>
                  <div
                    className="px-5 py-2 hover:bg-gray-100 cursor-pointer "
                    onClick={handleLogout}
                  >
                    <button className=" d-flex align-items-center">
                      <FiLogOut />
                      <div className="mx-2">Logout</div>
                    </button>
                  </div>
                </div>
              </div>
            </li>
          </div>
        )}
      </div>
    </div>
  );
};

export default AvatarSide;
