import React, { useState, useEffect, useRef } from "react";
import { FaPlus } from "react-icons/fa";
import "react-quill/dist/quill.snow.css";
import QuillEditor from "../QuillEditor";
import axios from "../../helpers/axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CreatePost = ({ community, addPost, getStars }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [contentType, setContentType] = useState("text");
  const [imageContent, setImageContent] = useState("");
  const [textContent, setTextContent] = useState("");
  const [fileContent, setFileContent] = useState("");
  const [videoContent, setVideoContent] = useState("");
  const [urlContent, setUrlContent] = useState("");
  const [userInfo, setUserInfo] = useState({});

  const handleQuillChange = (value) => {
    setTextContent(value);
  };

  const handleImageChange = (e) => {
    const { files } = e.target;
    setFileContent(files[0]);
  };
  const handleVideoChange = (e) => {
    const { files } = e.target;
    setVideoContent(files[0]);
  };

  useEffect(() => {
    const savedUserInfo = localStorage.getItem("userInfo");
    if (savedUserInfo) {
      setUserInfo(JSON.parse(savedUserInfo));
    }
  }, [setUserInfo]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    
    formData.append("title", title);
    formData.append("content_type", contentType);
    formData.append("community", community.id);
    formData.append("author", userInfo.userId);

    switch (contentType) {
      case "text":
        formData.append("text_content", textContent);
        break;
      case "image":
        formData.append("image_content", fileContent);
        break;
      case "video":
        formData.append("video_content", videoContent);
        break;
      case "url":
        formData.append("url_content", urlContent);
        break;
      case "file":
        formData.append("file_content", fileContent);
        break;
      default:
        break;
    }

    try {
      const response = await axios.post(`/posts/create/`, formData);
      if (response.data) {
        setLoading(false);
        toast.success("Post created successfully");
        setIsOpen(false);
        addPost(response.data);
        getStars();
      } else {
        setLoading(false);
        toast.error("Failed to create post, try again later");
      }
    } catch (error) {
      setLoading(false);
      console.log("Error occurred while creating Post:", error);
      toast.error("Failed to create post, try again later");
    }
  };

  return (
    <div className="">
      <button
        className="px-4 py-2 bg-[#198754] text-white rounded-md flex items-center"
        onClick={() => setIsOpen(true)}
      >
        <div>
          <FaPlus />
        </div>
        <div className="ml-2">Add Post</div>
      </button>

      {isOpen && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-md shadow-md w-[90%] md:w-[50%] max-h-[95vh] overflow-y-auto ">
            <div className="flex justify-between items-center">
              <h2 className="text-2xl font-semibold">Add Post</h2>
              <button
                className="text-gray-600 hover:text-gray-900"
                onClick={() => setIsOpen(false)}
              >
                <svg
                  className="h-6 w-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <form onSubmit={handleSubmit} className="space-y-6">
              <div>
                <label
                  htmlFor="title"
                  className="block text-sm font-medium text-gray-700"
                >
                  Title
                </label>
                <input
                  type="text"
                  id="title"
                  name="title"
                  required
                  minLength="1"
                  maxLength="200"
                  className="input input-bordered w-full"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>
              <div>
                <label className="block text-lg font-medium text-gray-700 text-center">
                  Content Type
                </label>
                <div className="mt-2">
                  <div className="tabs">
                    <a
                      className={`tab tab-lifted no-underline ${
                        contentType === "text"
                          ? "border-b-2 border-blue-500"
                          : ""
                      }`}
                      onClick={() => setContentType("text")}
                    >
                      Text
                    </a>
                    <a
                      className={`tab tab-lifted no-underline ${
                        contentType === "image"
                          ? "border-b-2 border-blue-500"
                          : ""
                      }`}
                      onClick={() => setContentType("image")}
                    >
                      Image
                    </a>
                    <a
                      className={`tab tab-lifted no-underline ${
                        contentType === "video"
                          ? "border-b-2 border-blue-500"
                          : ""
                      }`}
                      onClick={() => setContentType("video")}
                    >
                      Video
                    </a>
                    <a
                      className={`tab tab-lifted no-underline ${
                        contentType === "url"
                          ? "border-b-2 border-blue-500"
                          : ""
                      }`}
                      onClick={() => setContentType("url")}
                    >
                      URL
                    </a>
                    <a
                      className={`tab tab-lifted no-underline ${
                        contentType === "file"
                          ? "border-b-2 border-blue-500"
                          : ""
                      }`}
                      onClick={() => setContentType("file")}
                    >
                      File
                    </a>
                  </div>
                </div>
              </div>
              {contentType === "text" && (
                <div>
                  <label
                    htmlFor="text_content"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Text Content
                  </label>
                  <QuillEditor
                    value={textContent}
                    onChange={handleQuillChange}
                  />
                </div>
              )}
              {contentType === "image" && (
                <div>
                  <label
                    htmlFor="image_content"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Image
                  </label>
                  <input
                    type="file"
                    id="image_content"
                    name="image_content"
                    className="file-input file-input-bordered w-full"
                    onChange={handleImageChange}
                    accept="image/*"
                  />
                </div>
              )}
              {contentType === "video" && (
                <div>
                  <label
                    htmlFor="video_content"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Video Content
                  </label>
                  <input
                    type="file"
                    id="video_content"
                    name="video_content"
                    accept="video/*"
                    className="file-input file-input-bordered w-full"
                    onChange={(e) => setVideoContent(e.target.files[0])}
                  />
                </div>
              )}
              {contentType === "url" && (
                <div>
                  <label
                    htmlFor="url_content"
                    className="block text-sm font-medium text-gray-700"
                  >
                    URL Content
                  </label>
                  <div className="mt-1 flex rounded-md shadow-sm">
                    <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-3 text-sm text-gray-500">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-5 h-5"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                        />
                      </svg>
                    </span>
                    <input
                      type="url"
                      id="url_content"
                      name="url_content"
                      maxLength="200"
                      className="input input-bordered w-full"
                      placeholder="https://example.com"
                      value={urlContent}
                      onChange={(e) => setUrlContent(e.target.value)}
                    />
                  </div>
                </div>
              )}
              {contentType === "file" && (
                <div>
                  <label
                    htmlFor="file_content"
                    className="block text-sm font-medium text-gray-700"
                  >
                    File
                  </label>
                  <input
                    type="file"
                    id="file_content"
                    name="file_content"
                    className="file-input file-input-bordered w-full"
                    onChange={handleImageChange}
                    accept=".pdf"
                    
                  />
                </div>
              )}
              <div className="mt-6 flex justify-end">
                <button
                  className=" px-4 py-2 bg-gray-600 text-white rounded-md"
                  onClick={() => setIsOpen(false)}
                >
                  Close
                </button>
                <button
                  type="submit"
                  className="flex items-center ml-2 px-4 py-2 bg-blue-600 text-white rounded-md"
                  disabled={loading}
                >
                  {loading && (
                    <span className="loading loading-spinner loading-sm mr-2"></span>
                  )}{" "}
                  Add Post
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default CreatePost;
