import React, { useState, useEffect, useRef } from "react";
import { FaPlus } from "react-icons/fa";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import QuillEditor from "../QuillEditor";
import axios from "../../helpers/axios";
import { useForm } from "react-hook-form";
import { createCourseSchema } from "../validations/courseSchema";
import { yupResolver } from "@hookform/resolvers/yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CreateStudentsModal = ({ course }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef(null);

  const [name, setName] = useState("");
  const [file, setFile] = useState(null);

  const handleFileChange = (e) => {
    const { files } = e.target;
    if (files.length > 0) {
      setFile(files[0]);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!file) {
      toast.error("Please select a file before submitting.");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);
    setLoading(true);

    axios
      .post(`/courses/${course.id}/upload_excel/`, formData)
      .then((res) => {
        setLoading(false);
        console.log("res: ", res);
        if (res) {
          toast.success("Student Successful Added, Wait students join course");
          setFile(null);
          if (fileInputRef.current) {
            fileInputRef.current.value = "";
          }
          setIsOpen(false);
        } else {
          toast.error("Error while adding students, try later");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="">
      <button
        className="px-4 py-2 bg-[#198754] text-white rounded-md flex items-center"
        onClick={() => setIsOpen(true)}
      >
        <div>
          <FaPlus />
        </div>
        <div className="ml-2">Add Students</div>
      </button>

      {isOpen && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-md shadow-md w-[90%] md:w-[50%] max-h-[95vh] overflow-y-auto ">
            <div className="flex justify-between items-center">
              <h2 className="text-2xl font-semibold">Add Students</h2>
              <button
                className="text-gray-600 hover:text-gray-900"
                onClick={() => setIsOpen(false)}
              >
                <svg
                  className="h-6 w-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <form onSubmit={handleSubmit} className="space-y-6">
              <div>
                <label
                  htmlFor="communityImage"
                  className="block text-sm font-medium text-gray-700 mb-2"
                >
                  Add excel file
                </label>
                <input
                  type="file"
                  id="communityImage"
                  name="communityImage"
                  onChange={handleFileChange}
                  className="file-input file-input-bordered w-full"
                />
              </div>
              <div className="mt-6 flex justify-end">
                <button
                  className=" px-4 py-2 bg-gray-600 text-white rounded-md"
                  onClick={() => setIsOpen(false)}
                >
                  Close
                </button>
                <button
                  type="submit"
                  className="flex items-center ml-2 px-4 py-2 bg-blue-600 text-white rounded-md"
                  disabled={loading}
                >
                  {loading && (
                    <span className="loading loading-spinner loading-sm mr-2"></span>
                  )}{" "}
                  Upload and save students
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default CreateStudentsModal;
