import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Home from "./components/pages/Home";
import Header from "./components/Header";
import Footer from "./components/Footer";
import NotFound from "./components/pages/NotFound";
import NsangizaMainPage from "./components/pages/Nsangiza/Nsangiza";
import About from "./components/pages/About";
import Community from "./components/Community/Community";
import LongCourse from "./components/BwengeCourses/LongCourse";
import ShortCourse from "./components/BwengeCourses/ShortCouse";
import Courses from "./courses/Course";
import MoocHome from "./components/Bwenge Mooc/MoocHome";
import Mission from "./components/pages/Mission";
import Project from "./components/pages/Project";
import Article from "./components/pages/Article";
import Login from "./components/pages/Login";
import Register from "./components/pages/Register";
import Verify from "./components/pages/Verify";
import AccountHome from "./components/pages/Account/Home";
import Institution from "./components/pages/Account/HomeInstitutuition";
import "bootstrap/dist/css/bootstrap.min.css";
import "./components/styles/app.css";
import InstitutionRegister from "./components/pages/InstitutionRegister";
import HomeInstitution from "./components/pages/Account/HomeInstitutuition";
import Course from "./components/pages/Account/Course";
import { EditArticle } from "./components/pages/Account/Article";
import Quiz from "./components/pages/Account/quizzes/Quiz";
import { SingleCourse } from "./components/pages/SingleCourse";
import { Articles } from "./components/pages/Articles/Articles";
import { SingleProject } from "./components/pages/Projects/SingleProject";
import { TakeCourse } from "./components/pages/Courses/TakeCourse";
import { ViewPdf } from "./components/ViewPdf";
import { TakeQuiz } from "./components/pages/Courses/TakeQuiz";
import { SuccessQuiz } from "./components/pages/Courses/SuccessQuiz";
import SingleCommunity from "./components/pages/Community/SingleCommunity";
import { Profile } from "./components/pages/Profile/Profile";
import ForgotPassword from "./components/Auth/ForgotPassword";
import InstitutionProjects from "./components/pages/Institution/InstitutionProjects";
import { SingleInstitutionProjects } from "./components/pages/Institution/SingleInstitutionProjects";
import { ConfirmPassword } from "./components/Auth/ConfirmPassword";

const FooterWrapper = () => {
  const location = useLocation();
  const hideFooterPaths = ["/login", "/signup"];

  if (hideFooterPaths.includes(location.pathname)) {
    return null;
  }

  return <Footer />;
};

export default function App() {
  const [auth, setAuth] = useState(null);

  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      setAuth(JSON.parse(storedUser));
    }
  }, []);

  return (
    <BrowserRouter>
      <div className="body" style={{ backgroundColor: "#ffffff" }}>
        <div className="header">
          <Header auth={auth} setAuth={setAuth} />
        </div>
        <div className="Mainocontent">
          <Routes>
            <Route path="/" element={<Home auth={auth} />} />
            <Route path="/home" element={<Home />} />
            <Route path="/About" element={<About />} />
            <Route path="/communities" element={<Community auth={auth} />} />
            <Route
              path="/communities/:id"
              element={<SingleCommunity auth={auth} />}
            />
            <Route path="/courses" element={<Courses auth={auth} />} />
            <Route path="/course/:id" element={<SingleCourse auth={auth} />} />
            <Route
              path="/takeCourse/:id"
              element={<TakeCourse auth={auth} />}
            />
            <Route
              path="/takeCourse/:courseId/viewPdf/:lectureId"
              element={<ViewPdf />}
            />
            <Route
              path="/takeCourse/:courseId/takeQuiz/:quizId"
              element={<TakeQuiz />}
            />

            <Route
              path="/takeCourse/:courseId/takeQuiz/:quizId/success"
              element={<SuccessQuiz />}
            />
            <Route path="/mooc" element={<MoocHome />} />
            <Route path="/mission" element={<Mission />} />
            <Route path="/nsangiza" element={<NsangizaMainPage />} />
            <Route path="account/home" element={<AccountHome auth={auth} />} />
            <Route
              path="account/institution"
              element={<Institution auth={auth} />}
            />
            <Route path="/projects" element={<Project />} />
            <Route
              path="/institution/projects"
              element={<InstitutionProjects />}
            />
            <Route
              path="/institution/:id/projects"
              element={<SingleInstitutionProjects />}
            />
            <Route
              path="/project/:id"
              element={<SingleProject auth={auth} />}
            />
            <Route path="/articles" element={<Articles />} />
            <Route path="/article/:id" element={<Article />} />
            <Route path="/login" element={<Login setAuth={setAuth} />} />
            <Route path="/login/forgot" element={<ForgotPassword />} />
            <Route path="/signup" element={<Register />} />
            <Route path="/verify" element={<Verify setAuth={setAuth} />} />
            <Route path="/profile" element={<Profile />} />
            <Route
              path="/api/auth/password-reset-confirm/:uid/:token"
              element={<ConfirmPassword />}
            />
            <Route
              path="/institutionregister"
              element={<InstitutionRegister />}
            />
            <Route path="/homeinstitution" element={<HomeInstitution />} />
            <Route path="/account/course/:id" element={<Course />} />
            <Route path="/account/article/:id" element={<EditArticle />} />
            <Route
              path="/account/course/:courseId/quiz/:quizId"
              element={<Quiz />}
            />
            <Route path="*" element={<NotFound />} />
          </Routes>
          <Routes>
            <Route path="bwengecourses/longcourcse" element={<LongCourse />} />
            <Route path="bwengecourses/shortcourse" element={<ShortCourse />} />
          </Routes>
        </div>

        <div className="footer">
          <FooterWrapper />
        </div>
      </div>
    </BrowserRouter>
  );
}
