import React, { useState, useEffect, useRef } from "react";
import { FaPlus } from "react-icons/fa";
import "react-quill/dist/quill.snow.css";
import axios from "../../helpers/axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MdEdit } from "react-icons/md";
import { IoMdAdd } from "react-icons/io";
import { IoMdRemove } from "react-icons/io";

export const CreateChapterModal = ({ courseId, addChapter }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [chapters, setChapters] = useState([]);
  const [removedIds, setRemovedIds] = useState([]);

  // for chapters

  const handleAddTag = () => {
    setChapters([...chapters, { title: "" }]);
  };

  const handleChangeOptional = (index, field, value) => {
    const newChapters = [...chapters];
    newChapters[index][field] = value;
    setChapters(newChapters);
  };

  const handleRemoveTag = (index) => {
    const newChapters = [...chapters];
    const removedOption = newChapters.splice(index, 1)[0];
    if (removedOption.id) {
      setRemovedIds([...removedIds, removedOption.id]);
    }
    setChapters(newChapters);
  };

  const handleSubmitChapter = async (e) => {
    setLoading(true);
    let successfulRequests = 0;
    let failedRequests = 0;
    try {
      if (chapters.length) {
        const chapterPromises = chapters.map(async (chapter) => {
          const data = {
            title: chapter.title,
          };

          try {
            const res = await axios.post(
              `/course/${courseId}/chapters/create/`,
              data,
              {
                headers: {
                  "Content-Type": "application/json",
                },
              }
            );
            if (res) {
              successfulRequests += 1;
              addChapter(res.data);
            } else {
              failedRequests += 1;
            }
          } catch (error) {
            setLoading(false);
            console.log("Error occurred while create chapter:", error);
            toast.error("Failed to create chapter");
          }
        });

        await Promise.all(chapterPromises);
        setLoading(false);
        console.log("successfulRequests: ", successfulRequests);
        console.log("failedRequests: ", failedRequests);
        if (failedRequests === 0) {
          toast.success("All Chapters created successfully!");
          //   addCourse(course);
          setIsOpen(false);
          setChapters([]);
        } else {
          toast.error(`${failedRequests} chapter(s) failed to create`);
        }
      } else {
        setLoading(false);
        toast.error("Please add chapter title");
      }
    } catch (error) {
      setLoading(false);
      console.log("Error occurred while creating chapter:", error);
    }
  };
  return (
    <div>
      <div className="">
        <button
          className="px-4 py-2 bg-[#198754] text-white rounded-md flex items-center"
          onClick={() => setIsOpen(true)}
        >
          <div>
            <FaPlus />
          </div>
          <div className="ml-2">Add Chapter</div>
        </button>

        {isOpen && (
          <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white p-6 rounded-md shadow-md w-[90%] md:w-[50%] max-h-[95vh] overflow-y-auto ">
              <div className="flex justify-between items-center">
                <h2 className="text-2xl font-semibold">Add Chapter</h2>
                <button
                  className="text-gray-600 hover:text-gray-900"
                  onClick={() => setIsOpen(false)}
                >
                  <svg
                    className="h-6 w-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
              <form>
                <div className="my-2">
                  <div className="mt-4">
                    <h6 class="mb-2 font-semibold tex-lg capitalize">
                      {" "}
                      Add chapter titles *
                    </h6>
                    <div>
                      {chapters.map((option, index) => (
                        <div
                          key={index}
                          className="flex items-center mt-3 w-full   h-12"
                        >
                          <div className="w-full">
                            <input
                              type="text"
                              id="title"
                              value={option.title}
                              onChange={(e) =>
                                handleChangeOptional(
                                  index,
                                  "title",
                                  e.target.value
                                )
                              }
                              placeholder="Enter chapter title"
                              className="input input-bordered w-full "
                            />
                          </div>
                          <div
                            onClick={() => handleRemoveTag(index)}
                            className="border p-2 rounded-lg hover:opacity-75 ml-2 cursol-pointer"
                          >
                            <IoMdRemove />
                          </div>
                        </div>
                      ))}
                      <button
                        type="button"
                        className="bg-gray-400 hover:bg-gray-300 rounded py-1 px-2  flex items-center text-sm mt-2 transaction duration-400"
                        onClick={() => handleAddTag()}
                      >
                        <div className="mr-1">
                          <IoMdAdd />
                        </div>
                        <div>Add More</div>
                      </button>
                    </div>
                  </div>
                  <div className="mt-6 flex justify-end">
                    <button
                      className="px-4 py-2 bg-gray-600 text-white rounded-md"
                      onClick={() => setIsOpen(false)}
                    >
                      Close
                    </button>
                    <button
                      type="submit"
                      onClick={() => handleSubmitChapter()}
                      className="flex items-center ml-2 px-4 py-2 bg-blue-600 text-white rounded-md"
                      disabled={loading}
                    >
                      {loading && (
                        <span class="loading loading-spinner loading-sm mr-2"></span>
                      )}{" "}
                      Create Chapter
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
