import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "../helpers/axios";
import like from "../imgs/svg/like.svg";
import eye from "./../imgs/svg/eye.svg";
import chatting from "./../imgs/svg/chatting.svg";
import { LoginModal } from "./LoginModal";
import { useNavigate } from "react-router-dom";
import DOMPurify from "dompurify";

export const CourseCard = ({ course }) => {
  const [coursesLoading, setCoursesLoading] = useState(true);
  const [courses, setCourses] = useState([]);
  const [userInfo, setUserInfo] = useState({});
  const [isLogin, setIsLogin] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const savedUserInfo = localStorage.getItem("userInfo");
    if (savedUserInfo) {
      setUserInfo(JSON.parse(savedUserInfo));
    }
  }, []);

  return (
    <div className="">
      <Link
        className="block no-underline p cursor-pointer hover:bg-gray-100 mx-2"
        to={`/course/${course.id}`}
      >
        <div className="card courses">
          <img className="card-img-top" src={course.course_image} alt="image" />
          <div className="card-body">
            <h5 className="card-title">
              <b>{course.title}</b>
            </h5>
            <p
              className="card-text truncate-text"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(course.description),
              }}
            />
          </div>
        </div>
      </Link>
    </div>
  );
};
