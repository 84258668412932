import React, { useState } from "react";
import { FaChevronRight } from "react-icons/fa";
import { FaChevronDown } from "react-icons/fa";
import { IoMdLink } from "react-icons/io";
import { getFileNameFromUrl } from "../utils";
import { FaFileAlt } from "react-icons/fa";
import { Link } from "react-router-dom";

const ChapterAccordion = ({ chapter }) => {
  const [isChapter, setIsChapter] = useState(false);
  return (
    <div>
      <div className="mt-3 text-lg border-b pb-3">
        <div
          className="accordion-title flex items-center w-full cursor-pointer hover:bg-gray-100 px-4 py-3 transation duration-400"
          onClick={() => setIsChapter(!isChapter)}
        >
          <div className=" text-xl mr-4">
            {isChapter ? (
              <div>
                <FaChevronDown />
              </div>
            ) : (
              <div>
                <FaChevronRight />
              </div>
            )}
          </div>
          <h5 className="font-semibold text-[25px] px-2">{chapter.title}</h5>
        </div>
        {isChapter && (
          <div className=" py-3 px-10  border rounded">
            {chapter.lectures.length ? (
              <div>
                {chapter.lectures.map((lecture, index) => (
                  <div key={index}>
                    <h5 className="font-medium text-[20px] mb-3">
                      {lecture.title ? lecture.title : ""}
                    </h5>

                    {lecture.video_url && (
                      <div>
                        <div className="flex my-2">
                          <div className="border rounded flex items-center justify-center w-20">
                            <IoMdLink size={25} />{" "}
                          </div>
                          <div className="px-3 py-1 my-2">
                            <div>Video Link</div>
                            <div>
                              <a
                                href={lecture.video_url}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {lecture.video_url}
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="flex my-2">
                      <div className="border rounded flex items-center justify-center w-20">
                        <FaFileAlt size={25} />{" "}
                      </div>
                      <div className="px-3 py-1 my-2">
                        <div>File</div>
                        <div>
                          <Link to={`viewPdf/${lecture.id}`}>
                            {getFileNameFromUrl(lecture.pdf_url)}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div>
                <h5 className="">No lectures available</h5>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ChapterAccordion;
