import React, { useState, useEffect, useRef } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { ToastContainer, toast } from "react-toastify";
import QuillEditor from "../../QuillEditor";
import axios from "../../../helpers/axios";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { createCourseSchema } from "../../validations/courseSchema";
import CreateAssignmentModal from "../../Actions/CreateAssignmentModal";
import EditAssignmentModal from "../../Actions/EditAssignmentModal";
import DOMPurify from "dompurify";
import CreateQuizModal from "../../Actions/CreateQuizModal";
import DeleteQuizModal from "../../Actions/DeleteQuizModal";
import DeleteAssignModal from "../../Actions/DeleteAssignModal";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { MdEdit } from "react-icons/md";
import { Link } from "react-router-dom";
import Chapter from "./Chapter";
import { FaPlus } from "react-icons/fa";
import { CreateChapterModal } from "../../Actions/CreateChapterModal";
import CreateStudentsModal from "../../Actions/CreateStudentsModal";
import userImage from "../../../imgs/user.png";
import { GoBack } from "../../GoBack";

const Course = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [course, setCourse] = useState([]);
  const [courseLoading, setCourseLoading] = useState(true);
  const [assignmentLoading, setAssignmentLoading] = useState(true);
  const [assignments, setAssignments] = useState(false);
  const [quizzes, setQuizzes] = useState(false);
  const [quizLoading, setQuizLoading] = useState(false);
  const [chapters, setChapters] = useState([]);
  const [userInfo, setUserInfo] = useState({});
  const [students, setStudents] = useState([]);
  const [courseBelongsToUser, setCourseBelongsToUser] = useState(null);
  const navigate = useNavigate();
  const initialState = {
    id: "",
    title: "",
    description: "",
    teacher: "",
    course_image: null,
    imagePreview: null,
    enrollments: [],
  };
  const [courseData, setCourseData] = useState(initialState);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(createCourseSchema),
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCourseData({ ...courseData, [name]: value });
  };

  const handleQuillChange = (value) => {
    setCourseData((prevState) => ({
      ...prevState,
      description: value,
    }));
  };

  // redirect back to login
  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    if (!userInfo) {
      navigate("/login");
      return;
    }
  }, []);

  // assin login data to state
  useEffect(() => {
    const savedUserInfo = localStorage.getItem("userInfo");
    if (savedUserInfo) {
      setUserInfo(JSON.parse(savedUserInfo));
    }
  }, []);

  //   getting one course
  useEffect(() => {
    // setCourseLoading(true);
    axios
      .get(`/courses/${id}/`)
      .then((res) => {
        if (res.data) {
          setCourseLoading(false);
          if (res.data.teacher === userInfo.userId) {
            // console.log("course: ", res.data);
            setCourseData({
              id: res.data.id,
              title: res.data.title,
              description: res.data.description,
              course_image: null,
              imagePreview: res.data.course_image,
            });
            setChapters(res.data.chapters);
            setStudents(res.data.enrollments);
            setCourseBelongsToUser(true);
          } else {
            setCourseBelongsToUser(false);
          }
        } else {
          setCourseLoading(false);
          // console.log("error happen");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setCourseLoading(false);
      });
  }, [id, userInfo.userId]);

  // get assignments
  useEffect(() => {
    // setAssignmentLoading(true);
    axios
      .get(`/assignments/`)
      .then((res) => {
        if (res.data) {
          // console.log("assignments: ", res.data);
          const filteredAssignments = res.data.filter(
            (assignment) => assignment.course.id === courseData.id
          );
          setAssignments(filteredAssignments);
        } else {
          toast.error("No assignments found");
          // console.log("error happen");
        }
        setAssignmentLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        setAssignmentLoading(false);
      });
  }, [courseData.id]);

  // get quizzes
  useEffect(() => {
    setQuizLoading(true);
    axios
      .get(`/quizzes/`)
      .then((res) => {
        setQuizLoading(false);
        if (res.data) {
          // console.log('course.id: ',course)
          const filteredQuizzes = res.data.filter(
            (quiz) => quiz.course.id === courseData.id
          );
          setQuizzes(filteredQuizzes);
        } else {
          toast.error("No quizzes found");
          // console.log("error happen");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setQuizLoading(false);
      });
  }, [courseData.id]);

  const handleImageChange = (e) => {
    // console.log("get here in file");
    const { name, files } = e.target;
    const file = files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setCourseData({
          ...courseData,
          imagePreview: reader.result,
          [name]: file,
        });
      };
      // console.log("image res: ", courseData.imagePreview);
      reader.readAsDataURL(file);
    }
    setCourseData({ ...courseData, [name]: files[0] });
  };
  const handleEditCourse = async (e) => {
    setLoading(true);
    e.preventDefault();
    // console.log("courseData", courseData);
    const formData = new FormData();
    formData.append("title", courseData.title);
    formData.append("description", courseData.description);
    formData.append("teacher", 16);
    if (courseData.course_image instanceof File) {
      formData.append(
        "course_image",
        courseData.course_image,
        courseData.course_image.name
      );
    }

    await axios.patch(`/courses/${id}/`, formData).then((res) => {
      if (res.data) {
        setLoading(false);
        // console.log("res", res);
        toast.success("course updated successfully");
      } else {
        setLoading(false);
        toast.error("something went wrong while updating course");
      }
    });
  };

  const addAssignment = (newAssignment) => {
    setAssignments((prevAssignments) => [...prevAssignments, newAssignment]);
  };

  const editAssignment = (updatedAssignment) => {
    setAssignments((prevAssignments) =>
      prevAssignments.map((assignment) =>
        assignment.id === updatedAssignment.id ? updatedAssignment : assignment
      )
    );
  };

  const addQuiz = (newQuiz) => {
    setQuizzes((prevQuiz) => [...prevQuiz, newQuiz]);
  };

  // update quizzes after delete quiz without refresh page
  const removeQuiz = (quizId) => {
    setQuizzes((prevQuizzes) =>
      prevQuizzes.filter((quiz) => quiz.id != quizId)
    );
  };

  // update assignments after delete assignment without refresh page
  const removeAssignment = (assignmentId) => {
    setAssignments((prevAssignment) =>
      prevAssignment.filter((assignment) => assignment.id != assignmentId)
    );
  };

  const addChapter = (newChapter) => {
    setChapters((prevChapters) => [...prevChapters, newChapter]);
  };

  const editChapter = (updatedChapter) => {
    setChapters((prevChapters) =>
      prevChapters.map((chapter) =>
        chapter.id === updatedChapter.id ? updatedChapter : chapter
      )
    );
  };

  return (
    <div className="container mx-auto my-5">
      <GoBack />
      {courseLoading ? (
        <div class="flex justify-center w-[100%]">
          <span class="loading loading-bars loading-lg"></span>
        </div>
      ) : (
        <div>
          <h4 className="text-center mb-5 font-bold">
            Course title: {courseData.title}
          </h4>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-8">
            <div className="border rounded-lg p-5">
              <ToastContainer />
              <h3>Edit Course</h3>
              <form className="space-y-6">
                <div className="mt-4">
                  <div>
                    <label
                      htmlFor="title"
                      className="block text-sm font-medium text-gray-700 my-2"
                    >
                      Title
                    </label>
                    <input
                      type="text"
                      name="title"
                      id="title"
                      onChange={handleInputChange}
                      className="input input-bordered w-full"
                      value={courseLoading ? "loading..." : courseData.title}
                    />
                  </div>
                  <div className="my-2">
                    <label
                      htmlFor="description"
                      className="block text-sm font-medium text-gray-700 my-2"
                    >
                      Description
                    </label>
                    <QuillEditor
                      value={courseData.description}
                      onChange={handleQuillChange}
                    />
                  </div>
                  <div className="my-2">
                    <label
                      htmlFor="course_image"
                      className="block text-sm font-medium text-gray-700 my-2"
                    >
                      Course Image
                    </label>
                    {courseData.imagePreview && (
                      <div className="my-2">
                        <img
                          src={courseData.imagePreview}
                          alt="Course Preview"
                          className="w-[100px] h-[100px] object-cover my-2"
                        />
                      </div>
                    )}
                    <input
                      type="file"
                      name="course_image"
                      id="course_image"
                      onChange={handleImageChange}
                      className="file-input file-input-bordered file-input-md w-full"
                    />
                  </div>
                  {/* <div className="my-2">
                  <label
                    htmlFor="teacher"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Teacher
                  </label>
                  <input
                    type="number"
                    name="teacher"
                    id="teacher"
                    onChange={handleInputChange}
                    className="input input-bordered w-full"
                    defaultValue={courseData.teacher}
                    {...register("teacher")}
                  />
                  {errors.title && (
                    <span className="errorMsg text-danger text-sm">
                      {errors.teacher?.message}
                    </span>
                  )}
                </div> */}
                  {/* To be done in future */}
                </div>
                <div className="mt-6 flex justify-end">
                  <button
                    type="submit"
                    className="flex items-center ml-2 px-4 py-2 bg-blue-600 text-white rounded-md"
                    disabled={loading}
                    onClick={handleEditCourse}
                  >
                    {loading && (
                      <span class="loading loading-spinner loading-sm mr-2"></span>
                    )}{" "}
                    Edit Course
                  </button>
                </div>
              </form>
            </div>
            <div className="grid gap-4 md:gap-8">
              <div className="border rounded-lg p-5">
                <div className="flex items-center justify-between ">
                  <h4> Quizzes</h4>
                  <div>
                    <CreateQuizModal id={id} addQuiz={addQuiz} />
                  </div>
                </div>
                <div>
                  {quizLoading ? (
                    <div>loading...</div>
                  ) : (
                    <div>
                      {quizzes.length ? (
                        <div className="my-3 overflow-x-auto">
                          <table className="min-w-full bg-white border border-gray-200 rounded-lg shadow-md">
                            <thead>
                              <th className="py-3 px-6 bg-gray-100 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                                #
                              </th>
                              <th className="py-3 px-6 bg-gray-100 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                                title
                              </th>
                              <th className="py-3 px-6 bg-gray-100 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                                description
                              </th>
                              <th className="py-3 px-6 bg-gray-100 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                                action
                              </th>
                            </thead>
                            <tbody>
                              {quizzes.map((quiz, index) => (
                                <tr key={index} className="bg-gray-50 border-b">
                                  <td className="py-2 px-3 text-sm text-gray-500">
                                    {index + 1}
                                  </td>
                                  <td className="py-2 px-3 text-sm text-gray-500">
                                    {quiz.title}
                                  </td>
                                  <td className="py-2 px-3 text-sm text-gray-500">
                                    <p
                                      className="card-text truncate-text"
                                      dangerouslySetInnerHTML={{
                                        __html: DOMPurify.sanitize(
                                          quiz?.description
                                        ),
                                      }}
                                    />
                                  </td>
                                  <td className="flex items-center py-4 px-6 text-sm text-gray-500">
                                    <div className="">
                                      {/* <a
                                        href={`/account/course/${id}/quiz/${quiz.id}`}
                                        class=""
                                      >
                                        <MdEdit size={20} />
                                      </a> */}
                                      <Link
                                        to={`/account/course/${id}/quiz/${quiz.id}`}
                                        class=""
                                      >
                                        <MdEdit size={20} />
                                      </Link>
                                    </div>
                                    <div className="">
                                      <DeleteQuizModal
                                        id={quiz.id}
                                        removeQuiz={removeQuiz}
                                      />
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      ) : (
                        <div>No Quizzes found</div>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className="border rounded-lg p-5">
                <div className="flex items-center justify-between ">
                  <h4> Assignments</h4>
                  <div>
                    <CreateAssignmentModal
                      id={id}
                      addAssignment={addAssignment}
                    />
                  </div>
                </div>
                <div>
                  {assignmentLoading ? (
                    <div>loading...</div>
                  ) : (
                    <div>
                      {assignments.length ? (
                        <div className="my-3  overflow-x-auto">
                          <table className="min-w-full bg-white border border-gray-200 rounded-lg shadow-md">
                            <thead>
                              <th className="py-3 px-6 bg-gray-100 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                                #
                              </th>
                              <th className="py-3 px-6 bg-gray-100 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                                title
                              </th>
                              <th className="py-3 px-6 bg-gray-100 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                                description
                              </th>
                              <th className="py-3 px-6 bg-gray-100 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                                due date
                              </th>
                              <th className="py-3 px-6 bg-gray-100 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                                action
                              </th>
                            </thead>
                            <tbody>
                              {assignments.map((assignment, index) => (
                                <tr key={index} className="bg-gray-50 border-b">
                                  <td className="py-2 px-3 text-sm text-gray-500">
                                    {index + 1}
                                  </td>
                                  <td className="py-2 px-3 text-sm text-gray-500">
                                    {assignment.title}
                                  </td>
                                  <td className="py-2 px-3 text-sm text-gray-500">
                                    <p
                                      className="card-text truncate-text"
                                      dangerouslySetInnerHTML={{
                                        __html: DOMPurify.sanitize(
                                          assignment?.description
                                        ),
                                      }}
                                    />
                                  </td>
                                  <td className="py-2 px-3 text-sm text-gray-500">
                                    {new Date(
                                      assignment.due_date
                                    ).toLocaleDateString()}
                                  </td>
                                  <td className="py-2 px-3 text-sm text-gray-500">
                                    <td className="flex items-center py-4 px-6 text-sm text-gray-500">
                                      <div className="">
                                        <a href={`#`} class="">
                                          <EditAssignmentModal
                                            assignment={assignment}
                                            editAssignment={editAssignment}
                                          />
                                        </a>
                                      </div>
                                      <div className="">
                                        <DeleteAssignModal
                                          id={assignment.id}
                                          removeAssignment={removeAssignment}
                                        />
                                      </div>
                                    </td>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      ) : (
                        <div>No assignments found</div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="border rounded-lg p-5">
              <div className="flex items-center justify-between">
                <h4> Course Chapters</h4>
                <div>
                  <CreateChapterModal
                    courseId={courseData.id}
                    addChapter={addChapter}
                  />
                </div>
              </div>
              <div className=" max-h-[65vh] overflow-auto">
                {courseLoading ? (
                  <div>loading...</div>
                ) : (
                  <div>
                    {chapters.length ? (
                      <div>
                        {chapters.map((chapter, index) => (
                          <div key={index}>
                            <Chapter
                              chapter={chapter}
                              editChapter={editChapter}
                            />
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className="mt-3">No Chapters Found</div>
                    )}
                  </div>
                )}
              </div>
            </div>{" "}
            <div className="border rounded-lg p-5">
              <div className="flex items-center justify-between ">
                <h4> Course Students</h4>
                <CreateStudentsModal course={courseData} />
              </div>
              <div className="overflow-x-auto mt-3">
                {students.length ? (
                  <table className="min-w-full bg-white border border-gray-200 rounded-lg shadow-md">
                    <thead>
                      <th className="py-3 px-6 bg-gray-100 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                        #
                      </th>
                      <th className="py-3 px-6 bg-gray-100 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                        Student Name
                      </th>
                      <th className="py-3 px-6 bg-gray-100 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                        Student Email
                      </th>
                      {/* <th className="py-3 px-6 bg-gray-100 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                        Action
                      </th> */}
                    </thead>
                    <tbody>
                      {students.map((student) => (
                        <tr key={student.id} className="bg-gray-50 border-b">
                          <td className="py-4 px-6 text-sm text-gray-500">1</td>
                          <td className="flex items-center py-4 px-6 text-sm text-gray-500">
                            <div class="avatar">
                              <div class="w-12 rounded-full">
                                <img src={userImage} />
                              </div>
                            </div>
                            <div className="ml-3 text-md">
                              <b>{student.user_name}</b>
                            </div>
                          </td>
                          <td className="py-4 px-6 text-md text-gray-500">
                            {student.user_email}
                          </td>
                          {/* <td className="flex items-center py-4 px-6 text-sm text-gray-500">
                            <div className="">
                              <a href={`#`} class="">
                                <MdEdit size={24} />
                              </a>
                            </div>
                            <div>
                              <button className="text-red-500 font-medium">
                                <MdOutlineDeleteOutline size={24} />
                              </button>
                            </div>
                          </td> */}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <div>No Student Found</div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default Course;
