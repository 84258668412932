import React, { useState, useEffect } from "react";
import BwengeLogo from "../../imgs/BWENG.png";
import community from "../../imgs/community.png";
import computer from "../../imgs/computer.png";
import mechanics from "../../imgs/mechanical.png";
import skills from "../../imgs/skill.png";
import _ from "lodash";
import "./styles/communityHome.css";
import axios from "../../helpers/axios";
import DOMPurify from "dompurify";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { LoginModal } from "../LoginModal";
import { truncateText } from "../../utils";

const Community = ({ auth }) => {
  const [communities, setCommunities] = useState([]);
  const [trending, setTrending] = useState([]);
  const [activeTab, setActiveTab] = useState(1);
  const [categories, setCategories] = useState([]);
  const [categLoading, setCategLoading] = useState(true);
  const [activeCategory, setActiveCategory] = useState(null);
  const [userInfo, setUserInfo] = useState();
  const [isLogin, setIsLogin] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [joinedCommunities, setJoinedCommunities] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const savedUserInfo = localStorage.getItem("userInfo");
    if (savedUserInfo) {
      setUserInfo(JSON.parse(savedUserInfo));
    }
  }, [setUserInfo]);

  useEffect(() => {
    if (auth?.userEmail || userInfo?.userEmail) {
      setIsLogin(true);
    }
  }, [auth, userInfo]);

  useEffect(() => {
    axios
      .get("/communities/categories")
      .then((res) => {
        setCategLoading(false);
        if (res) {
          setCategories(res.data);
          if (res.data.length > 0) {
            setActiveCategory(res.data[0]);
          }
        }
      })
      .catch((error) => {
        setCategLoading(false);
        console.error("Error:", error);
      });
  }, []);

  useEffect(() => {
    const fetchCommunities = async () => {
      try {
        const response = await axios.get(
          "https://admin.bwenge.com/api/communities/"
        );
        setCommunities(response.data);
      } catch (error) {
        console.error("Failed to fetch communities:", error);
      }
    };
    const fetchTreding = async () => {
      try {
        const response = await axios.get(
          "https://admin.bwenge.com/api/add-article/"
        );
        setTrending(response.data);
      } catch (error) {
        console.error("Failed to fetch communities:", error);
      }
    };

    fetchCommunities();
    fetchTreding();
  }, []);

  const handleCategoryClick = (category) => {
    setActiveCategory(category);
  };

  const filteredCommunities = activeCategory
    ? communities.filter((community) =>
        community.categories.some((cat) => cat === activeCategory.id)
      )
    : [];

  const handleJoin = (communityId) => {
    if (isLogin) {
      const userId = userInfo.userId;

      const community = filteredCommunities.find((c) => c.id === communityId);

      if (community) {
        const isMember = community.members.some(
          (member) => member.id === userId
        );

        if (isMember) {
          const user = community.members.find((member) => member.id === userId);
        } else {
          joinCommunityApi(communityId);
        }
      }
    } else {
      setShowModal(true);
    }
  };

  const joinCommunityApi = (communityId) => {
    
    axios
      .post(`/communities/${communityId}/join/`, { user_id: userInfo.userId })
      .then((res) => {
        if (res.data) {
          navigate(`/communities/${communityId}/`);
        } else {
          toast.error("Fail to Join community, Try later");
        }
      })
      .catch(() => {
        toast.error("Fail to Join community, Try later");
      });
  };

  return (
    <div>
      <ToastContainer />
      <img src={community} alt="Image..." />
      <div className="container">
        <div className="px-2 my-10 text-lg font-medium">
          <span className="text-xl">
            Through Community Education, learners can develop relationships, a
            spirit of teamwork, and problem-solving skills to overcome the
            diverse challenges in our society. It is also a tool that encourages
            learners to engage in self-learning. The system will rank the best
            content creators as Stars, giving them the opportunity to
            participate in various project ideas and job opportunities.
          </span>
        </div>
        {!isLogin && showModal && (
          <LoginModal
            setIsLogin={setIsLogin}
            onClose={() => setShowModal(false)}
            content="You need to login to join community"
          />
        )}
        <div className="community-cards">
          <div className="row">
            <div className="col-md-7">
              <div className="tabs ">
                {categLoading ? (
                  <div>Loading...</div>
                ) : (
                  <>
                    {categories.length ? (
                      <div className="">
                        {categories.slice(0, 3).map((category) => (
                          <button
                            key={category.id}
                            className={`mx-3 text-lg py-3 px-3 font-medium ${
                              activeCategory?.id === category.id
                                ? "border-b-2 border-blue-500"
                                : ""
                            }`}
                            onClick={() => handleCategoryClick(category)}
                          >
                            {category.name}
                          </button>
                        ))}
                      </div>
                    ) : (
                      <div>No categories found</div>
                    )}
                  </>
                )}
              </div>

              <div className="content">
                {filteredCommunities.length ? (
                  filteredCommunities.map((community) => (
                    <div key={community.id} className="">
                      <div className="row no-gutters mt-5">
                        <div className="col-md-6 ">
                          <div className=" flex justify-center ">
                            <img
                              src={community.poster_image}
                              className="object-cover w-[80%] rounded"
                              alt="Community"
                            />
                          </div>
                        </div>
                        <div className="col-md-6 flex items-center justify-center text-center">
                          <div className="card-body text-center">
                            <h5 className="card-title">
                              <b>{community.name}</b>
                            </h5>
                            <span className="">
                              {truncateText(community.description)}
                            </span>

                            <span className=" font-medium">
                              Members:{" "}
                              {community.members ? community.members.length : 0}
                            </span>

                            {community.members &&
                            community.members.length > 0 ? (
                              community.members.some(
                                (member) => member.id === userInfo?.userId
                              ) ? (
                                <Link
                                  to={`/communities/${community.id}`}
                                  className="bg-gray-300 px-3 py-2 text-white mt-3 rounded font-medium hover:bg-blue-600 text-lg no-underline"
                                >
                                  Joined (View)
                                </Link>
                              ) : (
                                <button
                                  onClick={() => handleJoin(community.id)}
                                  className="bg-blue-700 px-3 py-2 text-white mt-3 rounded font-medium hover:bg-blue-600 text-lg"
                                >
                                  Join
                                </button>
                              )
                            ) : (
                              <button
                                onClick={() => handleJoin(community.id)}
                                className="bg-blue-700 px-3 py-2 text-white mt-3 rounded font-medium hover:bg-blue-600 text-lg"
                              >
                                Join
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="mt-3">
                    No communities found for this category
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-5">
              <div class="custom-heading">
                <h5 class="mb-0">TOP HEADING</h5>
              </div>
              <div class="card-cont card">
                {trending.slice(0, 5).map((trending) => (
                  <div key={trending.id} class="row no-gutters">
                    <div class="col-md-3 border-r  flex items-center justify-center">
                      <img
                        src={trending.poster_image}
                        className=" h-20  w-20 rounded object-cover"
                        alt={trending.title}
                      />
                    </div>
                    <div class="col-md-7">
                      <div class="card-body">
                        <h5 class="card-title">{trending.title}</h5>
                        <p
                          className="card-text custom-paragraph-style"
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(
                              truncateText(trending.description),
                              {
                                ALLOWED_TAGS: ["p"],
                                ALLOWED_ATTR: [],
                              }
                            ),
                          }}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Community;
