import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Modal,
  Form,
} from "react-bootstrap";
import "react-quill/dist/quill.snow.css"; // import the styles
import axios from "../../../helpers/axios";
import userprofile from "../../../imgs/Logowhite.jpg";
import "../../styles/account.css";
import Notiflix from "notiflix";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import CreateArticleModal from "../../Actions/CreateArticleModal";
import DOMPurify from "dompurify";
import CreateCourseModal from "../../Actions/CreateCourseModal";
import CreateCommunityModal from "../../Actions/CreateCommunityModal";
import CreateProjectModal from "../../Actions/CreateProjectModal";
import DeleteCourseModal from "../../Actions/DeleteCourseModal";
import { MdEdit } from "react-icons/md";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Description from "../../Description";
import { DeleteArticleModal } from "../../Actions/DeleteArticleModal";
import { DeleteProjectModal } from "../../Actions/DeleteProjectModal";
import { Link, useNavigate } from "react-router-dom";
import { DeleteCommunityModal } from "../../Actions/DeleteCommunityModal";

function CountCard({ title, count, bgColorClass }) {
  return (
    <Card className={`count-card ${bgColorClass}`}>
      <Card.Body>
        <h6>{title}</h6>
        <h2>{count}</h2>
      </Card.Body>
    </Card>
  );
}
function AccountHome({ auth }) {
  const [userInfo, setUserInfo] = useState({});
  const [courses, setCourses] = useState([]);
  const [courseLoading, setCourseLoading] = useState(true);
  const [projects, setProjects] = useState([]);
  const [articleData, setArticleData] = useState({
    title: "",
    description: "",
    categories: [],
    author: userInfo?.id,
  });
  const [articles, setArticles] = useState([]);
  const [articleLoading, setArticleLoading] = useState(true);
  const [communities, setCommunities] = useState([]);
  const [statistics, setStatistics] = useState();
  const [communityLoading, setCommunityLoading] = useState(true);
  const [loading, setLoading] = useState(true);
  const [userProfile, setUserProfile] = useState({});

  const [newCommunityId, setNewCommunityId] = useState(null); // Track newly created community ID
  const newCommunityRef = useRef(null); // Reference to the new community element
  let no = 1;
  let cNo = 1;
  let comNo = 1;
  let projNo = 1;

  const navigate = useNavigate();
  // redirect back to login
  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    if (!userInfo) {
      navigate("/login");
      return;
    }
  }, []);

  // assin login data to state
  useEffect(() => {
    const savedUserInfo = localStorage.getItem("userInfo");
    if (savedUserInfo) {
      setUserInfo(JSON.parse(savedUserInfo));
    }
  }, []);

  useEffect(() => {
    if (userInfo.userId) {
      axios
        .get(`/auth/profile/${userInfo.userId}/`)
        .then((res) => {
          setLoading(false);
          if (res.data) {
            setUserProfile(res.data);
          } else {
            // setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  }, [userInfo]);

  useEffect(() => {
    axios
      .get("/add-article/")
      .then((res) => {
        setArticleLoading(false);
        const filteredArticles = res?.data.filter(
          (article) => article.author === userInfo.userId
        );

        setArticles(filteredArticles);
      })
      .catch((error) => {
        setArticleLoading(false);
      });
  }, [userInfo.userId]);

  useEffect(() => {
    axios
      .get("/courses/")
      .then((res) => {
        setCourseLoading(false);
        // console.log("courses: ", res.data);
        const filteredCourses = res.data.filter(
          (course) => course.teacher === userInfo.userId
        );
        setCourses(filteredCourses);
      })
      .catch((error) => {
        console.error("Error:", error);
        setCourseLoading(false);
      });
  }, [userInfo.userId]);

  useEffect(() => {
    axios
      .get("/mystatistics/")
      .then((data) => {
        setStatistics(data.data);
        setCourseLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        setCourseLoading(false);
      });
  }, []);

  // console.log("statistics", statistics);

  // // Function to handle the category selection
  // const handleCategorySelect = (e) => {
  //   const selectedOptions = Array.from(
  //     e.target.selectedOptions,
  //     (option) => option.value
  //   );
  //   setCategoryIds(selectedOptions);
  // };

  // Function to handle the category deselection
  // const handleCategoryDeselect = (e) => {
  //   const deselectedOption = e.target.value;
  //   setCategoryIds((prevSelectedCategoryIds) =>
  //     prevSelectedCategoryIds.filter((id) => id !== deselectedOption)
  //   );
  // };

  useEffect(() => {
    const fetchCommunities = async () => {
      try {
        const response = await axios.get("/communities/");
        // console.log("comm res", response);
        setCommunityLoading(false);
        if (response) {
          // console.log("response", response);
          const filteredCommunities = response.data.filter(
            (community) => community.admin === userInfo.userId
          );
          setCommunities(filteredCommunities);
        }
      } catch (error) {
        setCommunityLoading(false);
        console.error("Failed to fetch communities:", error);
      }
    };

    fetchCommunities();
  }, [newCommunityId, userInfo.userId]); // Fetch communities when newCommunityId changes

  // Scroll to the newly created community
  useEffect(() => {
    if (newCommunityId && newCommunityRef.current) {
      newCommunityRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [communities]);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axios.get("/projects/");
        if (response) {
          const filteredProjects = response.data.filter(
            (project) => project.author.id === userInfo.userId
          );
          setProjects(filteredProjects);
        }
      } catch (error) {
        console.error("Failed to fetch projects:", error);
      }
    };

    fetchProjects();
  }, [userInfo.userId]);

  const handleCategoryChange = (e) => {
    const { checked, value } = e.target;
    let updatedCategories = [...articleData.categories];

    if (checked) {
      updatedCategories.push(parseInt(value));
    } else {
      updatedCategories = updatedCategories.filter(
        (category) => category !== parseInt(value)
      );
    }

    setArticleData({
      ...articleData,
      categories: updatedCategories,
    });
  };

  // update courses after create new one without refresh
  const addArticle = (newArticle) => {
    setArticles((prevArticles) => [...prevArticles, newArticle]);
  };

  // update courses after create new one without refresh
  const addCommunity = (newCommunity) => {
    setCommunities((prevCommunities) => [...prevCommunities, newCommunity]);
  };

  // update courses after create new one without refresh
  const addCourse = (newCourse) => {
    setCourses((prevCourses) => [...prevCourses, newCourse]);
  };

  // update project after create new one without refresh
  const addProject = (newProject) => {
    setProjects((prevProject) => [...prevProject, newProject]);
  };

  // update courses after delete course without refresh
  const removeCourse = (courseId) => {
    setCourses((prevCourses) =>
      prevCourses.filter((course) => course.id != courseId)
    );
  };
  // update courses after delete course without refresh
  const removeArticle = (articleId) => {
    setArticles((prevArticle) =>
      prevArticle.filter((article) => article.id != articleId)
    );
  };
  const removeProject = (projectId) => {
    setProjects((prevProject) =>
      prevProject.filter((project) => project.id != projectId)
    );
  };

  return (
    <Container className="mt-5">
      <ToastContainer />
      <Row>
        <Col>
          <Card className="jumbotron">
            <Card.Body>
              <Row>
                <Col
                  md={3}
                  className="flex justify-center items-center py-3 md:py-2"
                >
                  {loading ? (
                    <div>
                      <span class="loading loading-spinner loading-sm mr-2"></span>
                    </div>
                  ) : (
                    <div class="avatar">
                      <div class="w-40 rounded-xl">
                        <img src={userProfile.profile_picture} />
                      </div>
                    </div>
                  )}
                </Col>
                <Col md={3}>
                  {userInfo && (
                    <>
                      <h3>{auth?.fullName || userInfo.fullName}</h3>
                      <p>{auth?.userEmail || userInfo.userEmail}</p>
                      <p>phone: {auth?.phone || userInfo.phone}</p>
                      {/* <p>status: {userInfo.status}</p> */}
                      <p>Role: {auth?.role || userInfo.role}</p>
                    </>
                  )}
                </Col>
                <Col md={6}>
                  <Row>
                    <Col md={4}>
                      <CountCard
                        title="My Courses"
                        count={courses.length}
                        bgColorClass="my-courses"
                      />
                    </Col>
                    <Col md={4}>
                      <CountCard
                        title="My Projects"
                        count={projects.length}
                        bgColorClass="my-projects"
                      />
                    </Col>
                    <Col md={4}>
                      <CountCard
                        title="My Community"
                        count={communities.length}
                        bgColorClass="my-community"
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <div className="my-5">
        <Tabs>
          <TabList className={{ marginBottom: "30px" }}>
            <Tab>My Articles</Tab>
            <Tab>My Course</Tab>
            <Tab>My Community</Tab>
            <Tab>MY Project</Tab>
          </TabList>

          {/* article */}
          <TabPanel>
            <div className=" flex justify-end">
              <CreateArticleModal articles={articles} addArticle={addArticle} />
            </div>
            <div className="my-3">
              {articleLoading ? (
                <div class="flex justify-center w-[100%]">
                  <span class="loading loading-bars loading-lg"></span>
                </div>
              ) : (
                <div>
                  {articles?.length ? (
                    <div className="overflow-x-auto">
                      <table className="min-w-full bg-white border border-gray-200 rounded-lg shadow-md">
                        <thead>
                          <th className="py-3 px-6 bg-gray-100 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                            #
                          </th>
                          <th className="py-3 px-6 bg-gray-100 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                            Article Name
                          </th>
                          <th className="py-3 px-6 bg-gray-100 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                            Article Description
                          </th>
                          <th className="py-3 px-6 bg-gray-100 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                            Action
                          </th>
                        </thead>
                        <tbody>
                          {articles.map((article) => (
                            <tr class="bg-gray-50 border-b">
                              <td className="py-4 px-6 text-sm text-gray-500">
                                {no++}
                              </td>
                              <td className="flex items-center py-4 px-6 text-sm text-gray-500">
                                <div>
                                  {/* <img
                                    src={article.poster_image}
                                    alt="article image"
                                    className="w-16 h-16 rounded-full object-cover"
                                  /> */}
                                  <div class="avatar">
                                    <div class="w-20 rounded-xl">
                                      <img src={article.poster_image} />
                                    </div>
                                  </div>
                                </div>
                                <div className="ml-3">
                                  <b>{article.title}</b>
                                </div>
                              </td>
                              <td className="py-4 px-6 text-sm text-gray-500">
                                <Description
                                  description={article.description}
                                  showMore={false}
                                  maxLength={150}
                                />
                              </td>
                              <td className="flex items-center py-4 px-6 text-sm text-gray-500">
                                <div className="">
                                  <Link
                                    to={`/account/article/${article.id}`}
                                    class=""
                                  >
                                    <MdEdit size={24} />
                                  </Link>
                                </div>
                                <div>
                                  <DeleteArticleModal
                                    id={article.id}
                                    removeArticle={removeArticle}
                                  />
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <div>No article created</div>
                  )}
                </div>
              )}
            </div>
          </TabPanel>
          {/* course */}
          <TabPanel>
            <div className=" flex justify-between items-center">
              <div className="font-semibold text-lg">Mooc Courses</div>
              <CreateCourseModal auth={auth} addCourse={addCourse} />
            </div>
            <div className="my-3">
              {courseLoading ? (
                <div class="flex justify-center w-[100%]">
                  <span class="loading loading-bars loading-lg"></span>
                </div>
              ) : (
                <div>
                  {courses?.length ? (
                    <div className="overflow-x-auto">
                      <table className="min-w-full bg-white border border-gray-200 rounded-lg shadow-md">
                        <thead>
                          <th className="py-3 px-6 bg-gray-100 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                            #
                          </th>
                          <th className="py-3 px-6 bg-gray-100 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                            Course Name
                          </th>
                          <th className="py-3 px-6 bg-gray-100 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                            Course Description
                          </th>
                          <th className="py-3 px-6 bg-gray-100 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                            Action
                          </th>
                        </thead>
                        <tbody>
                          {courses.map((course) => (
                            <tr class="bg-gray-50 border-b">
                              <td className="py-4 px-6 text-sm text-gray-500">
                                {cNo++}
                              </td>
                              <td className="flex items-center py-4 px-6 text-sm text-gray-500">
                                <div>
                                  <img
                                    src={course.course_image}
                                    alt="course image"
                                    className="w-12 h-12 rounded-full"
                                  />
                                </div>
                                <div className="ml-3">
                                  <b>{course.title}</b>
                                </div>
                              </td>
                              <td className="py-4 px-6 text-sm text-gray-500">
                                <Description
                                  description={course.description}
                                  showMore={false}
                                  maxLength={150}
                                />
                              </td>
                              <td className="flex items-center py-4 px-6 text-sm text-gray-500">
                                <div className="">
                                  <Link
                                    to={`/account/course/${course.id}`}
                                    class=""
                                  >
                                    <MdEdit size={24} />
                                  </Link>
                                </div>
                                <div>
                                  <DeleteCourseModal
                                    id={course.id}
                                    removeCourse={removeCourse}
                                  />
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <div>No course created</div>
                  )}
                </div>
              )}
            </div>
          </TabPanel>
          {/* community */}
          <TabPanel>
            <div className=" flex justify-end">
              <CreateCommunityModal addCommunity={addCommunity} auth={auth} />
            </div>
            <div className=" mt-4">
              {communityLoading ? (
                <div class="flex justify-center w-[100%]">
                  <span class="loading loading-bars loading-lg"></span>
                </div>
              ) : (
                <div>
                  {communities?.length ? (
                    <div className="overflow-x-auto">
                      <table className="min-w-full bg-white border border-gray-200 rounded-lg shadow-md">
                        <thead>
                          <th className="py-3 px-6 bg-gray-100 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                            #
                          </th>
                          <th className="py-3 px-6 bg-gray-100 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                            Community Name
                          </th>
                          <th className="py-3 px-6 bg-gray-100 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                            Community Description
                          </th>
                          <th className="py-3 px-6 bg-gray-100 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                            Created Date
                          </th>
                          <th className="py-3 px-6 bg-gray-100 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                            Action
                          </th>
                        </thead>
                        <tbody>
                          {communities.map((community, index) => (
                            <tr class="bg-gray-50 border-b" key={index}>
                              <td className="py-4 px-6 text-sm text-gray-500">
                                {comNo++}
                              </td>
                              <td className="flex items-center py-4 px-6 text-sm text-gray-500">
                                <div>
                                  <img
                                    src={community.poster_image}
                                    alt={community.name}
                                    className="w-12 h-12 rounded-full"
                                  />
                                </div>
                                <div className="ml-3">
                                  <b>{community.name}</b>
                                </div>
                              </td>
                              <td className="py-4 px-6 text-sm text-gray-500">
                                <Description
                                  description={community.description}
                                  showMore={false}
                                  maxLength={150}
                                />
                              </td>
                              <td>
                                <div className="ml-3">
                                  {new Date(
                                    community.created_date
                                  ).toLocaleDateString()}
                                </div>
                              </td>
                              <td className="flex items-center py-4 px-6 text-sm text-gray-500">
                                <div className="">
                                  <Link
                                    to={`/account/community/${community.id}`}
                                    class=""
                                  >
                                    <MdEdit size={24} />
                                  </Link>
                                </div>
                                <div>
                                  <DeleteCommunityModal
                                    id={community.id}
                                    removeCourse={removeCourse}
                                  />
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <div>No community created</div>
                  )}
                </div>
              )}
            </div>
          </TabPanel>
          {/* projects */}
          <TabPanel>
            <div className=" flex justify-end">
              <CreateProjectModal addProject={addProject} />
            </div>
            {projects.length ? (
              <div className="overflow-x-auto relative shadow-md sm:rounded-lg mt-4">
                <table className="w-full text-sm text-left text-gray-500">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="py-3 px-6 bg-gray-100 text-left text-xs font-medium text-gray-600 uppercase tracking-wider"
                      >
                        #
                      </th>
                      <th
                        scope="col"
                        className="py-3 px-6 bg-gray-100 text-left text-xs font-medium text-gray-600 uppercase tracking-wider"
                      >
                        Title
                      </th>
                      <th
                        scope="col"
                        className="py-3 px-6 bg-gray-100 text-left text-xs font-medium text-gray-600 uppercase tracking-wider"
                      >
                        Authors
                      </th>
                      <th
                        scope="col"
                        className="py-3 px-6 bg-gray-100 text-left text-xs font-medium text-gray-600 uppercase tracking-wider"
                      >
                        Level
                      </th>
                      <th
                        scope="col"
                        className="py-3 px-6 bg-gray-100 text-left text-xs font-medium text-gray-600 uppercase tracking-wider"
                      >
                        Submission Date
                      </th>
                      <th
                        scope="col"
                        className="py-3 px-6 bg-gray-100 text-left text-xs font-medium text-gray-600 uppercase tracking-wider"
                      >
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {projects.map((project) => (
                      <tr
                        key={project.id}
                        className="bg-white border-b hover:bg-gray-50"
                      >
                        <td className="py-4 px-6">{projNo++}</td>
                        <td className="py-4 px-6">{project.topics}</td>
                        <td className="py-4 px-6">
                          {project.author.first_name}
                        </td>
                        <td className="py-4 px-6">{project.level}</td>
                        <td className="py-4 px-6">
                          {new Date(
                            project.submitted_date
                          ).toLocaleDateString()}
                        </td>
                        <td className="py-4 px-6">
                          <div className="flex items-center">
                            <Link
                              to={`/account/project/${project.id}`}
                              className="font-medium text-blue-600 hover:underline mr-3"
                            >
                              <MdEdit size={20} />
                            </Link>
                            <DeleteProjectModal
                              id={project.id}
                              removeProject={removeProject}
                            />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="my-2">No project found</div>
            )}
          </TabPanel>
        </Tabs>
      </div>
    </Container>
  );
}

export default AccountHome;
