export  const formatDate = (date) => {
    const d = new Date(date);
    const month = "" + (d.getMonth() + 1);
    const day = "" + d.getDate();
    const year = d.getFullYear();

    return [year, month.padStart(2, "0"), day.padStart(2, "0")].join("-");
  };

  export  const getFileNameFromUrl = (url) => {
    return url.split("/").pop();
  };

  
  export const truncateText = (text) => {
    if (text.length > 60) {
      return text.substring(0, 60) + "...";
    }
    return text;
  };