import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
// import { LoginModal } from "../../components/LoginModal";
import { useNavigate } from "react-router-dom";
import DOMPurify from "dompurify";
import axios from "../../../helpers/axios";
import { FaDownload } from "react-icons/fa";
import { LoginModal } from "../../LoginModal";
import { saveAs } from "file-saver";

export const SingleProject = ({ auth }) => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [project, setProject] = useState([]);
  const [isLogin, setIsLogin] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const userInfo = JSON.parse(localStorage.getItem("userInfo")) || {};
  const [isDownloading, setIsDownloading] = useState(false);

  // useEffect(() => {
  //   const savedUserInfo = localStorage.getItem("userInfo");
  //   if (savedUserInfo) {
  //     setUserInfo(JSON.parse(savedUserInfo));
  //   }
  // }, []);

  //   console.log("auth: ", auth);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`/projects/${id}/`)
      .then((res) => {
        if (res.data) {
          setLoading(false);
          setProject(res.data);
        } else {
          setLoading(false);
          console.log("error happen");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setLoading(false);
      });
  }, [id]);

  useEffect(() => {
    if (auth?.userEmail || userInfo?.userEmail) {
      setIsLogin(true);
    }
  }, [auth, userInfo]);

  const handleDownloadClick = async () => {
    if (!isLogin) {
      setShowModal(true);
      return; // Prevent further execution if not logged in
    }
    setIsDownloading(true);
    const handleDownload = () => {
      const fileUrl = project.file;
      const link = document.createElement("a");
      link.href = fileUrl;
      link.setAttribute("download", `${project.topics}.zip`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      setIsDownloading(false);
    };

    handleDownload();
  };

  return (
    <div className="container my-10">
      <div>
        <div>
          {loading ? (
            <div>
              <div class="flex justify-center w-[100%]">
                <span class="loading loading-bars loading-lg"></span>
              </div>
            </div>
          ) : (
            <div>
              <div>
                <div className="flex justify-between">
                  <h2 className="mb-3">{project.topics}</h2>
                  <div>
                    <button
                      onClick={() => handleDownloadClick(project)}
                      className="btn btn-primary text-lg font-medium "
                    >
                      <div className="flex items-center text-white block">
                        <FaDownload />
                        <div className="ml-2 text-white">Download</div>
                      </div>
                    </button>
                    {!isLogin && showModal && (
                      <LoginModal
                        setIsLogin={setIsLogin}
                        onClose={() => setShowModal(false)}
                        content={"Please login to download the file"}
                      />
                    )}
                  </div>
                </div>
                <div className="mt-3">
                  <p className="text-lg">{project.description}</p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
