import React from "react";
import { Link } from "react-router-dom";

export const JoinUs = ({ title }) => {
  return (
    <div className="mt-4">
      <div className="flex flex-col items-center justify-center">
        {title && (
          <h1 className="font-semibold text-center text-5xl md:text-5xl text-[#3e4095]">
            {title}
          </h1>
        )}
        <div className="my-3">
          <Link
            to={"/login"}
            className="bg-mainColor text-white text-lg sm:text-3xl md:text-3xl font-semibold rounded-xl px-[40px] py-2  mr-3 no-underline hover:opacity-75"
          >
            Member
          </Link>
          <Link
            to={"/signup"}
            className="bg-mainColor text-white text-lg sm:text-3xl md:text-3xl font-semibold rounded-xl px-[40px] py-2  mr-3 no-underline hover:opacity-75"
          >
            institution
          </Link>
        </div>
      </div>
    </div>
  );
};
