import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "../../../helpers/axios";
import like from "../../../imgs/svg/like.svg";
import eye from "../../../imgs/svg/eye.svg";
import chatting from "../../../imgs/svg/chatting.svg";
import { LoginModal } from "../../../components/LoginModal";
import { useNavigate } from "react-router-dom";
import DOMPurify from "dompurify";
import { useFormatDate } from "../../../hooks";
import { truncateText } from "../../../utils";

export const Articles = () => {
  const formatDate = useFormatDate();
  const [articles, setArticles] = useState([]);
  const [articleErrorMsg, setArticleErrorMsg] = useState("");
  const [isArticleLoading, setIsArticleLoading] = useState(true);

  const getArticles = () => {
    setIsArticleLoading(true);
    fetch("https://admin.bwenge.com/api/add-article/")
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setArticles(data);
        setIsArticleLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching articles:", error);
        setIsArticleLoading(false);
        setArticleErrorMsg("Failed to load articles. Please try again later.");
      });
  };

  useEffect(() => {
    getArticles();
  }, []);

  if (isArticleLoading) {
    return (
      <div className="container my-10">
        <h2 className="mb-3">Articles</h2>
        <div className="text-center">
        <div class="flex justify-center w-[100%]">
            <span class="loading loading-bars loading-lg"></span>
          </div>
        </div>
      </div>
    );
  }

  if (articleErrorMsg) {
    return (
      <div className="container my-10">
        <h2 className="mb-3">Articles</h2>
        <div className="alert alert-danger" role="alert">
          {articleErrorMsg}
        </div>
      </div>
    );
  }

  return (
    <div className="container my-10">
      <h2 className="mb-3">Articles</h2>

      <div className="row">
        {articles.length > 0 ? (
          articles.map((article) => (
            <div className="col-md-3 mt-4" key={article.id}>
              <div className="card">
                <img
                  className="card-img-top"
                  src={article.poster_image}
                  alt="image"
                />
                <div className="card-body">
                  <h5 className="card-title">
                    <b>{article.title}</b>
                  </h5>
                  <p
                    className="card-text"
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(
                        truncateText(article.description)
                      ),
                    }}
                  />
                  <div>
                    <div className="button-container">
                      <Link
                        to={`/article/${article.id}`}
                        className="readmore btn"
                      >
                        Read More
                      </Link>
                      <span className="date">{formatDate(article.date)}</span>
                    </div>
                    {/* <div className="icon-container">
                      <img src={like} alt="icon" />
                      <img src={eye} alt="icon" />
                      <img src={chatting} alt="icon" />
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div>
            <center>No Available Articles</center>
          </div>
        )}
      </div>
    </div>
  );
};