import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "../../helpers/axios";

const VerifyOTPForm = ({ setAuth }) => {
  const [otp, setOtp] = useState("");
  const [logging, setLogging] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleChange = (e) => {
    setOtp(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLogging(true);
    try {
      const res = await axios.post("/auth/verify-email/", { otp });

      if (res.status) {
        // Store user info in local storage
        const userInfo = {
          access_token: res?.data?.data?.access_token,
          refresh_token: res?.data?.data?.refresh_token,
          userEmail: res?.data?.data?.user?.email,
          fullName: res?.data?.data?.user?.full_name,
          phone: res?.data?.data?.user?.telephone,
          role: res?.data?.data?.user?.role,
          userId: res?.data?.data?.user?.id,
          status: res.data.status,
        };
        // console.log('user data:::', res?.data)
        localStorage.setItem("userInfo", JSON.stringify(userInfo));
        setAuth(userInfo);
        setTimeout(() => {
          if (userInfo.role === "user") {
            //   navigate("/account/home");
            window.location.href = "/account/home";
          } else if (userInfo.role === "institution_admin") {
            window.location.href = "/account/institution";
          } else {
            window.location.href = "/";
            // navigate("/");
          }
        }, 200);
        // navigate("/account/home");
      } else {
        setError("Invalid response format");
      }
    } catch (err) {
      // console.error('Error:', err);
      setError(err.response?.data?.message || "An error occurred");
    } finally {
      setLogging(false);
    }
  };

  return (
    <div className="login-container">
      <div className="verify-content bg-white p-10 rounded">
        <div className="verify-form">
          <h2>Confirm Your Account!</h2>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <input
                type="number"
                id="otp"
                placeholder="Enter your OTP"
                value={otp}
                onChange={handleChange}
                required
              />
            </div>
            {logging ? <p>Sending...</p> : error && <p>{error}</p>}
            <button type="submit" className="login-btn">
              Verify
            </button>
          </form>
          <div className="login-options">
            <span>
              {" "}
              didn&#39;t receive an OTP? <a href="#">Resend</a>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyOTPForm;
