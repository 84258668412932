import React, { useEffect, useState } from "react";
import { FaFileAlt } from "react-icons/fa";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "../../../helpers/axios";
import "../../styles/course.css";
import { MdQuiz } from "react-icons/md";
import DOMPurify from "dompurify";
import { toast } from "react-toastify";

export const SuccessQuiz = () => {
  const { courseId, quizId } = useParams();
  const [courseLoading, setCourseLoading] = useState(false);
  const [course, setCourse] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [quiz, setQuiz] = useState({
    title: "",
    description: "",
    questions: [],
  });
  const [selectedChoices, setSelectedChoices] = useState({});
  const [currentIndex, setCurrentIndex] = useState(0);
  const questionsPerPage = 5;
  let no = 1;
  const navigate = useNavigate();
  //   console.log("id:", lectureId);

  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    if (!userInfo) {
      navigate("/login");
      return;
    }
  }, []);

  const handleNext = () => {
    setCurrentIndex(currentIndex + questionsPerPage);
  };

  const handleBack = () => {
    setCurrentIndex(currentIndex - questionsPerPage);
  };

  const handleChoiceChange = (questionId, choiceId) => {
    setSelectedChoices({
      ...selectedChoices,
      [questionId]: choiceId,
    });
  };

  useEffect(() => {
    setCourseLoading(true);
    axios
      .get(`/quiz/${quizId}/`)
      .then((res) => {
        setCourseLoading(false);
        if (res.data) {
          setQuiz(res.data);
        } else {
          console.log("error happen");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setCourseLoading(false);
      });
  }, []);
  // console.log("quize", quiz);

  const [randomPercentage, setRandomPercentage] = useState(0);

  useEffect(() => {
    // Generate a random number between 0 and 100
    const randomNum = Math.floor(Math.random() * 101);
    setRandomPercentage(randomNum);
  }, []);

  return (
    <div className="container my-10">
      {courseLoading ? (
        <div class="flex justify-center w-[100%]">
          <span class="loading loading-bars loading-lg"></span>
        </div>
      ) : (
        <div>
          <div className="flex my-2 ">
            <div className="border rounded flex items-center justify-center w-20">
              <MdQuiz size={25} />{" "}
            </div>
            <div className="px-3 py-1 my-2">
              <div className=" text-xl">Quiz</div>
              <div>
                <h2>{quiz.title ? quiz.title : ""}</h2>
              </div>
            </div>
          </div>
          <p
            className="card-text truncate-text text-xl"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(quiz.description),
            }}
          />
          <div className="bg-gray-100 rounded-3xl p-10 pb-20 mt-3 shadow-lg text-center mt-5">
            <h1 className="text-4xl font-bold text-green-600 mb-4">
              Congratulations!
            </h1>
            <p className="text-xl mb-6">
              You have achieved the following result:
            </p>
            <h1 className="text-5xl font-extrabold text-blue-500">
              {randomPercentage}%
            </h1>
          </div>
        </div>
      )}
    </div>
  );
};
