import React, { useState, useEffect, useRef } from "react";
import { FaPlus } from "react-icons/fa";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import QuillEditor from "../QuillEditor";
import axios from "../../helpers/axios";
import { useForm } from "react-hook-form";
import { createCourseSchema } from "../validations/courseSchema";
import { yupResolver } from "@hookform/resolvers/yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CreateCommunityModal = ({ addCommunity, auth }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [categoryIds, setCategoryIds] = useState([]);
  const [communityImage, setCommunityImage] = useState(null);
  const [category, setCategory] = useState("");
  const [userInfo, setUserInfo] = useState({});
  const [categories, setCategories] = useState([]);
  const [categLoading, setCategLoading] = useState(true);

  useEffect(() => {
    const savedUserInfo = localStorage.getItem("userInfo");
    if (savedUserInfo) {
      setUserInfo(JSON.parse(savedUserInfo));
    }
  }, []);

  useEffect(() => {
    axios
      .get("/communities/categories")
      .then((res) => {
        setCategLoading(false);
        if (res) {
          setCategories(res.data);
        }
      })
      .catch((error) => {
        setCategLoading(false);
        console.error("Error:", error);
      });
  }, []);

  // console.log("categ: ", categories);

  const handleFileChange = (e) => {
    setCommunityImage(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append("name", name);
    formData.append("description", description);
    formData.append("admin", userInfo.userId);
    formData.append("categories", category);
    // console.log("category", category);
    if (communityImage) {
      formData.append("poster_image", communityImage, communityImage.name);
    }
    try {
      await axios
        .post("/communities/create/", formData)
        .then((res) => {
          if (res.data) {
            addCommunity(res.data);
            setLoading(false);
            toast.success("Community created successfully!");
            setIsOpen(false);
            setName("");
            setDescription("");
            setCommunityImage(null);
          } else {
            setLoading(false);
            toast.error("Failed to create community");
          }
        })
        .catch((error) => {
          setLoading(false);
          toast.error("Failed to create community");
        });
      //   setArticles([...communities, response.data]);
      //   setShowCommunityModal(false);
      //   setNewCommunityId(response.data.id);
    } catch (error) {
      setLoading(false);
      console.log("Error occurred while creating community:", error);
    }
  };

  return (
    <div className="">
      <button
        className="px-4 py-2 bg-[#198754] text-white rounded-md flex items-center"
        onClick={() => setIsOpen(true)}
      >
        <div>
          <FaPlus />
        </div>
        <div className="ml-2">Create Community</div>
      </button>

      {isOpen && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-md shadow-md w-[90%] md:w-[50%] max-h-[95vh] overflow-y-auto ">
            <div className="flex justify-between items-center">
              <h2 className="text-2xl font-semibold">Create An community</h2>
              <button
                className="text-gray-600 hover:text-gray-900"
                onClick={() => setIsOpen(false)}
              >
                <svg
                  className="h-6 w-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <form onSubmit={handleSubmit} className="space-y-6">
              <div>
                <label
                  htmlFor="communityName"
                  className="block text-sm font-medium text-gray-700 mb-2"
                >
                  Name
                </label>
                <input
                  type="text"
                  id="communityName"
                  placeholder="Enter name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                  className="input input-bordered w-full"
                />
              </div>
              <div className=" ">
                <label
                  htmlFor="communityDescription"
                  className="block text-sm font-medium text-gray-700 mb-2"
                >
                  Description
                </label>
                <textarea
                  id="communityDescription"
                  rows={7}
                  placeholder="Enter description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  className="border p-3 rounded w-full "
                />
              </div>
              {/* <div>
                <label
                  htmlFor="communityCategories"
                  className="block text-sm font-medium text-gray-700 mb-2"
                >
                  Categories
                </label>
                <select
                  id="communityCategories"
                  value={categoryIds}
                  onChange={(e) =>
                    setCategoryIds(
                      Array.from(
                        e.target.selectedOptions,
                        (option) => option.value
                      )
                    )
                  }
                  className="select select-bordered w-full"
                >
                  {availableCategories.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
                </select>
              </div> */}
              <div>
                <label
                  htmlFor="communityImage"
                  className="block text-sm font-medium text-gray-700 mb-2"
                >
                  Image
                </label>
                <input
                  type="file"
                  id="communityImage"
                  name="communityImage"
                  onChange={handleFileChange}
                  className="file-input file-input-bordered w-full"
                  accept="image/*"
                />
              </div>
              <div>
                <label
                  htmlFor="communityImage"
                  className="block text-sm font-medium text-gray-700 mb-2"
                >
                  Categories
                </label>

                <div>
                  <select
                    id="category"
                    value={category}
                    onChange={(e) => setCategory(e.target.value)}
                    required
                    className="select select-bordered w-full"
                  >
                    <option value={""} checked>
                      Select category
                    </option>
                    {categLoading ? (
                      <div>loading...</div>
                    ) : (
                      <>
                        {categories.map((category) => (
                          <option value={category.id}>{category.name}</option>
                        ))}
                      </>
                    )}
                  </select>
                </div>
              </div>
              <div className="mt-6 flex justify-end">
                <button
                  className=" px-4 py-2 bg-gray-600 text-white rounded-md"
                  onClick={() => setIsOpen(false)}
                >
                  Close
                </button>
                <button
                  type="submit"
                  className="flex items-center ml-2 px-4 py-2 bg-blue-600 text-white rounded-md"
                  disabled={loading}
                >
                  {loading && (
                    <span className="loading loading-spinner loading-sm mr-2"></span>
                  )}{" "}
                  Create Community
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default CreateCommunityModal;
