import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "../helpers/axios";
import like from "../imgs/svg/like.svg";
import eye from "./../imgs/svg/eye.svg";
import chatting from "./../imgs/svg/chatting.svg";
import { LoginModal } from "../components/LoginModal";
import { useNavigate } from "react-router-dom";
import DOMPurify from "dompurify";

function Courses({ auth }) {
  const [coursesLoading, setCoursesLoading] = useState(true);
  const [courses, setCourses] = useState([]);
  const [filteredCourses, setFilteredCourses] = useState([]);
  const [userInfo, setUserInfo] = useState({});
  const [isLogin, setIsLogin] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const savedUserInfo = localStorage.getItem("userInfo");
    if (savedUserInfo) {
      setUserInfo(JSON.parse(savedUserInfo));
    }
  }, []);

  // get courses
  useEffect(() => {
    axios
      .get("/courses/")
      .then((response) => {
        const filteredCourse = response.data.filter(
          (course) =>
            course.course_type.toLowerCase() === "regular" ||
            (course.course_type.toLowerCase() === "mooc" &&
              course.is_approved_status.toLowerCase() !== "awaiting approval")
        );
        setCourses(filteredCourse);
        setFilteredCourses(filteredCourse);
        setCoursesLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        setCoursesLoading(false);
      });
  }, []);

  const handleSearch = (searchTerm) => {
    const filtered = courses.filter((course) =>
      course.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      course.description.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredCourses(filtered);
  };

  return (
    <div className="container my-10">
      <h2 className="mb-3">Courses</h2>
      <div>
        <div className="mb-4 flex">
          <div className="bg-gray-200 px-3 font-medium flex items-center">
            <p>Titles</p>
          </div>
          <input
            type="text"
            placeholder="Search courses..."
            className="w-full px-4 py-3 border border-gray-300  focus:outline-none focus:ring-2 focus:ring-blue-500"
            onChange={(e) => handleSearch(e.target.value)}
          />
        </div>
      </div>
      <div>
        <div>
          {coursesLoading ? (
            <div class="flex justify-center w-[100%]">
              <span class="loading loading-bars loading-lg"></span>
            </div>
          ) : (
            <div>
              {isLogin && (
                <LoginModal
                  setIsLogin={setIsLogin}
                  content={"You need to login to access this page"}
                />
              )}
              {filteredCourses?.length ? (
                <div className="grid grid-cols-2 md:grid-cols-4 gap-4 w-full">
                  {filteredCourses.map((course) => (
                    <Link
                      className="block no-underline p cursor-pointer"
                      to={`/course/${course.id}`}
                    >
                      <div className="card courses">
                        <img
                          className="card-img-top"
                          src={course.course_image}
                          alt="image"
                        />
                        <div className="card-body">
                          <h5 className="card-title">
                            <b>{course.title}</b>
                          </h5>
                          <p
                            className="card-text truncate-text"
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(course.description),
                            }}
                          />
                        </div>
                      </div>
                    </Link>
                  ))}
                </div>
              ) : (
                <div className="text-center">
                  <h1 className="text-center text-2xl font-bold mb-4">
                    No courses available
                  </h1>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Courses;