import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { Slides } from "../../Slides";
import { Link, useParams } from "react-router-dom";

const InstitutionProjects = () => {
  const { id } = useParams();
  const [projects, setProjects] = useState([]);
  const [projectLoading, setProjectLoading] = useState(true);
  const [institution, setInstitution] = useState([]);
  const [institutionLoading, setInstitutionLoading] = useState(true);
  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axios.get(
          "https://admin.bwenge.com/api/projects/"
        );
        setProjectLoading(false);
        if (response) {
          //   console.log("projects: ", response.data);
          setProjects(response.data);
        }
      } catch (error) {
        setProjectLoading(false);
        console.error("Failed to fetch projects:", error);
      }
    };

    fetchProjects();
  }, []);





  return (
    <div className="container mt-5">
      <div className="bg-gray-200 w-full p-5 rounded-lg grid grid-cols-1 md:grid-cols-2">
        <div>
          <h1>Thesis & Dissertation Database</h1>
          <div className="flex flex-col md:flex-row items-center space-x-3 my-3">
            <div>Bachelor’s</div>
            <div className="bg-white border p-2 rounded">
              {" "}
              Doctoral Disertations
            </div>
            <div className="bg-white border p-2 rounded"> Masters Thesis</div>
          </div>
          <p>
            Database Including the Rwandan doctoral dissertations, master’s
            thesis, and bachelor’s projects from all universities and colleges
            in Rwanda. It includes high-quality literature and continuous
            dynamic update.
          </p>
        </div>
        <div className="p-5">
          <Slides slidesToShow={3}>
            <div class="avatar">
              <div class="w-28 rounded-full">
                <img src="https://img.daisyui.com/images/stock/photo-1534528741775-53994a69daeb.webp" />
              </div>
            </div>
            <div class="avatar">
              <div class="w-28 rounded-full">
                <img src="https://img.daisyui.com/images/stock/photo-1534528741775-53994a69daeb.webp" />
              </div>
            </div>
            <div class="avatar">
              <div class="w-28 rounded-full">
                <img src="https://img.daisyui.com/images/stock/photo-1534528741775-53994a69daeb.webp" />
              </div>
            </div>
          </Slides>
        </div>
      </div>

      <div className="mt-5">
        <div className="ui raised segment">
          {projectLoading ? (
            <div className="flex justify-center w-[100%]">
              <span className="loading loading-bars loading-lg"></span>
            </div>
          ) : (
            <>
              {projects.length ? (
                <div>
                  <table className="ui celled table">
                    <thead>
                      <tr>
                        <th>Title</th>
                        <th>Authors</th>
                        <th>Level</th>
                        <th>Submission Date</th>
                        <th>action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {projects.map((project) => (
                        <tr key={project.id}>
                          <td>{project.topics}</td>
                          <td>{`${project.author.first_name} ${project.author.last_name}`}</td>
                          <td>{project.level}</td>
                          <td>
                            {new Date(
                              project.submitted_date
                            ).toLocaleDateString()}
                          </td>
                          <td>
                            <Link
                              to={`/project/${project.id}`}
                              className="no-underline"
                            >
                              View
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div>No projects found</div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default InstitutionProjects;
