import * as yup from 'yup';

export const loginSchema = yup.object({
    email: yup.string().required(),
    password: yup.string().required(),
}).required();

export const adminLoginSchema = yup.object({
    adminEmail: yup.string().required(),
    adminPassword: yup.string().required(),
}).required();

export const signupSchema = yup.object({
    fname: yup.string().required(),
    lname: yup.string().required(),
    phone: yup.string().required(),
    email: yup.string().email().required(),
    password: yup.string().required().min(6, "Password length should be at least 6 characters"),
    confirmPassword: yup.string().required().oneOf([yup.ref("password")], "Passwords do not match"),
}).required();

export const institutionSchema = yup.object({
    name: yup.string().required(),
    phone: yup.string().required(),
    email: yup.string().email().required(),
    password: yup.string().required().min(6, "Password length should be at least 6 characters"),
    confirmPassword: yup.string().required().oneOf([yup.ref("password")], "Passwords do not match"),
}).required();