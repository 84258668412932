import React, { useState, useEffect, useRef } from "react";
import { FaPlus } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import axios from "../../helpers/axios";

export const SubmitModal = ({ assignment }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userInfo, setUserInfo] = useState();
  const [title, setTitle] = useState("");
  const [file, setFile] = useState(null);
  const fileInputRef = useRef(null);

  useEffect(() => {
    const savedUserInfo = localStorage.getItem("userInfo");
    if (savedUserInfo) {
      setUserInfo(JSON.parse(savedUserInfo));
    }
  }, [setUserInfo]);

  const handleImageChange = (e) => {
    const { files } = e.target;
    if (files.length > 0) {
      setFile(files[0]);
    }
  };
  //   console.log("userInfo", userInfo);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!file) {
      toast.error("Please select a file before submitting.");
      return;
    }
    console.log("ass id: ", assignment.id);
    const formData = new FormData();
    formData.append("assignment_id", assignment?.id);
    formData.append("user_id", userInfo.userId);
    formData.append("file", file);
    setLoading(true);

    try {
      axios.post("/submissions/create/", formData).then((res) => {
        setLoading(false);
        // console.log("submit: ", res);
        if (res) {
          toast.success("Submission successful");
          setFile(null);
          if (fileInputRef.current) {
            fileInputRef.current.value = "";
          }
          setIsOpen(false);
        } else {
          toast.error("Error while submitting, try later");
        }
      });
    } catch (error) {
      toast.error("Error while submitting, try later");
      setLoading(false);
    }
  };

  return (
    <div className="">
      <button
        className="px-4 py-2 bg-[#198754] text-white rounded-md flex items-center"
        onClick={() => setIsOpen(true)}
      >
        <div className="ml-2">Submit Assignment</div>
      </button>

      {isOpen && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-md shadow-md w-[90%] md:w-[50%] ">
            <div className="flex justify-between items-center">
              <h2 className="text-2xl font-semibold">
                Submit {assignment.title}
              </h2>
              <button
                className="text-gray-600 hover:text-gray-900"
                onClick={() => setIsOpen(false)}
              >
                <svg
                  className="h-6 w-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <form onSubmit={handleSubmit} className="space-y-6">
              <div className="my-2">
                <label className="block text-sm font-medium text-gray-700 my-2">
                  Add file submition
                </label>
                <input
                  type="file"
                  name="file"
                  id="file"
                  ref={fileInputRef}
                  onChange={handleImageChange}
                  className="file-input file-input-bordered file-input-md w-full"
                />
              </div>

              <div className="mt-6 flex justify-end">
                <button
                  className=" px-4 py-2 bg-gray-600 text-white rounded-md"
                  onClick={() => setIsOpen(false)}
                >
                  Close
                </button>
                <button
                  type="submit"
                  className="flex items-center ml-2 px-4 py-2 bg-blue-600 text-white rounded-md"
                  disabled={loading}
                >
                  {loading && (
                    <span className="loading loading-spinner loading-sm mr-2"></span>
                  )}{" "}
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};
