import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "../../../helpers/axios";
import { toast, ToastContainer } from "react-toastify";
// import axios from "axios";

export const Profile = () => {
  const [userInfo, setUserInfo] = useState({});
  const [userProfile, setUserProfile] = useState({
    birth_date: "",
    profile_image: "",
    location: "",
    bio: "",
  });
  const [loading, setLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [initialImage, setInitialImage] = useState(""); 
  const [newImage, setNewImage] = useState(""); 
  const [error, setError] = useState(null); 

  const navigate = useNavigate();
  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    if (!userInfo) {
      navigate("/login");
      return;
    }
  }, []);

  // assin login data to state
  useEffect(() => {
    const savedUserInfo = localStorage.getItem("userInfo");
    if (savedUserInfo) {
      setUserInfo(JSON.parse(savedUserInfo));
    }
  }, []);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setUserProfile({
        ...userProfile,
        profile_image: file,
      });

      const reader = new FileReader();
      reader.onloadend = () => {
        setNewImage(reader.result); // Set the preview URL for the new image
      };
      reader.readAsDataURL(file);
    } else {
      setNewImage(""); // Reset new image preview if no file is selected
    }
  };

  useEffect(() => {
    console.log(userInfo);
    if (userInfo.userId) {
      axios
        .get(`/auth/profile/${userInfo.userId}/`)
        .then((res) => {
          setLoading(false);
          console.log(userInfo)
          if (res?.data) {
            setUserProfile(res.data);
            setInitialImage(res.data.profile_picture);
            setError(null); // Clear any previous errors
          } else {
            setError("An error occurred. Please try again later.");
          }
        })
        .catch((error) => {
          setLoading(false);
          setError("An error occurred. Please try again later.");
        });
    }
  }, [userInfo]);

  const handleEditProfile = async () => {
    setIsEditing(true);

    // Assuming you have form fields for name, email, and bio
    const formData = new FormData();
    formData.append("birth_date", userProfile.birth_date);
    formData.append("location", userProfile.location);
    formData.append("bio", userProfile.bio);
    formData.append("profile_picture", userProfile.profile_image);

    await axios
      .put(`/auth/profile/${userInfo.userId}/`, formData)
      .then((res) => {
        setIsEditing(false);
        if (res.data) {
          toast.success("Profile updated successfully");
        } else {
          toast.error("Error updating profile");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setIsEditing(false);
      });
  };

  return (
    <div className="container my-10">
      <ToastContainer />
      {loading ? (
        <div className="flex justify-center w-[100%]">
          <span className="loading loading-bars loading-lg"></span>
        </div>
      ) : error ? (
        <div role="alert" class="alert alert-warning">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6 shrink-0 stroke-current"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
            />
          </svg>
          <span>{error}</span>
        </div>
      ) : (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleEditProfile();
          }}
        >
          <div className="flex justify-center">
            <div className="w-[95%] sm:w-[80%] md:w-[60%] max-auto border rounded p-5  ">
              <div className="grid grid-cols-1 sm:grid-cols-3">
                <div className="col-span-1 sm:border-r px-3">
                  <div className="relative">
                    <div className="my-2">
                      {newImage ? (
                        <div className="avatar">
                          <div className="w-24 rounded-full">
                            <img src={newImage} alt="Profile" />
                          </div>
                        </div>
                      ) : initialImage ? (
                        <div className="avatar">
                          <div className="w-24 rounded-full">
                            <img src={initialImage} alt="Profile" />
                          </div>
                        </div>
                      ) : (
                        <p>No image available</p>
                      )}
                    </div>
                    <label
                      htmlFor="profile_image"
                      className="cursor-pointer absolute bottom-0 left-0 bg-blue-500 hover:bg-blue-700 p-2 rounded-full text-white"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V9z"
                        />
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M15 13a3 3 0 11-6 0 3 3 0 016 0z"
                        />
                      </svg>
                    </label>
                    <input
                      type="file"
                      name="profile_image"
                      id="profile_image"
                      onChange={handleImageChange}
                      className="hidden"
                    />
                  </div>
                </div>
                <div className="col-span-2 p-4">
                  <div className="mb-4">
                    <label
                      htmlFor="birth_date"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Birth Date
                    </label>
                    <input
                      type="date"
                      id="birth_date"
                      name="birth_date"
                      className="border p-3 rounded w-full focus:outline-none"
                      value={userProfile.birth_date}
                      onChange={(e) =>
                        setUserProfile({
                          ...userProfile,
                          birth_date: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="mb-4">
                    <label
                      htmlFor="location"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Location
                    </label>
                    <input
                      type="text"
                      id="location"
                      name="location"
                      className="border p-3 rounded w-full focus:outline-none"
                      value={userProfile.location}
                      onChange={(e) =>
                        setUserProfile({
                          ...userProfile,
                          location: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="mb-4">
                    <label
                      htmlFor="bio"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Bio
                    </label>
                    <textarea
                      id="bio"
                      name="bio"
                      rows="3"
                      className="border p-3 rounded w-full focus:outline-none"
                      value={userProfile.bio}
                      onChange={(e) =>
                        setUserProfile({ ...userProfile, bio: e.target.value })
                      }
                    ></textarea>
                  </div>
                  <div>
                    <button
                      type="submit"
                      className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      disabled={isEditing}
                    >
                      {isEditing && (
                        <span class="loading loading-spinner loading-sm mr-2"></span>
                      )}{" "}
                      Save Changes
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};
