import React, { useState, useEffect, useRef } from "react";
import { FaPlus } from "react-icons/fa";
import "react-quill/dist/quill.snow.css";
import QuillEditor from "../QuillEditor";
import axios from "../../helpers/axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MdEdit } from "react-icons/md";
import { IoMdAdd } from "react-icons/io";
import { IoMdRemove } from "react-icons/io";

export const AddQuestionOptional = ({ question, editQuestion }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [title, setTitle] = useState(question.text || "");
  const [removedIds, setRemovedIds] = useState([]);

  useEffect(() => {
    if (question) {
      setOptions(
        question.choices.map((choice) => {
          return {
            id: choice.id,
            title: choice.text,
            isCorrect: choice.is_correct,
          };
        })
      );
    }
  }, [question.id]);
  // console.log("options:", options);
  const handleAddTag = () => {
    setOptions([...options, { link: "", pdf: '' }]);
  };

  const handleChangeOptional = (index, field, value) => {
    const newOptions = [...options];
    newOptions[index][field] = value;
    setOptions(newOptions);
  };

  const handleRemoveTag = (index) => {
    // setOptions(options.filter((_, i) => i !== index));
    const newOptions = [...options];
    const removedOption = newOptions.splice(index, 1)[0];
    if (removedOption.id) {
      setRemovedIds([...removedIds, removedOption.id]);
    }
    setOptions(newOptions);
  };

  const handleCheckboxChange = (index) => {
    const newOptions = [...options];
    newOptions[index].isCorrect = !newOptions[index].isCorrect;
    setOptions(newOptions);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (title) {
      const formData = new FormData();
      formData.append("text", title);

      //  edit question
      try {
        await axios
          .put(`/questions/${question.id}/`, formData)
          .then((res) => {
            if (res.data) {
              // console.log("res", res);
              setLoading(false);
              toast.success("Question Updated successfully!");
              setIsOpen(false);
              editQuestion(res.data);
            } else {
              setLoading(false);
              toast.error("Failed to Updated question");
            }
          })
          .catch((error) => {
            setLoading(false);
            toast.error("Failed to Updated question");
          });
      } catch (error) {
        setLoading(false);
        console.log("Error occurred while Updated question:", error);
      }
    }

    if (options.length) {
      const promises = options.map(async (option, index) => {
        const data = {
          text: option.title,
          is_correct: option.isCorrect,
        };

        try {
          let res;
          if (option.id) {
            res = await axios.put(`/choices/${option.id}/`, data, {
              headers: {
                "Content-Type": "application/json",
              },
            });
            if (!res) {
              toast.error("Failed to update choice");
            }
          } else {
            res = await axios.post(
              `/questions/${question.id}/choices/create/`,
              data,
              {
                headers: {
                  "Content-Type": "application/json",
                },
              }
            );
          }

          if (!res) {
            toast.error("Failed to create choice");
          }
        } catch (error) {
          setLoading(false);
          console.log("Error occurred while creating choice:", error);
        }
      });
      
      const deletePromises = removedIds.map(async (id) => {
        try {
          const res = await axios.delete(`/choices/${id}/`, {
            headers: {
              "Content-Type": "application/json",
            },
          });

          if (!res?.status === 204) {
            toast.error("Failed to delete question option");
          }
        } catch (error) {
          console.log("Error occurred while deleting choice:", error);
          toast.error("Failed to delete question option");
        }
      });

      try {
        await Promise.all([...promises, ...deletePromises]);
        toast.success("All options updates successfully!");
        setIsOpen(false);
      } catch (error) {
        toast.error("An error occurred while updating/deleting options.");
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className="">
      <button className="text-blue-500" onClick={() => setIsOpen(true)}>
        <MdEdit size={20} />
      </button>

      {isOpen && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-md shadow-md w-[90%] md:w-[50%] max-h-[95vh] overflow-y-auto ">
            <div className="flex justify-between items-center">
              <h2 className="text-xl font-semibold">Edit Question</h2>
              <button
                className="text-gray-600 hover:text-gray-900"
                onClick={() => setIsOpen(false)}
              >
                <svg
                  className="h-6 w-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <form onSubmit={handleSubmit} className="space-y-6">
              <div className=" ">
                <div>
                  <h6 class="mb-2 font-semibold tex-lg capitalize">
                    {" "}
                    Question *
                  </h6>
                  <input
                    type="text"
                    id="title"
                    placeholder="Enter title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    required
                    className="input input-bordered w-full"
                  />
                </div>
                <div className="mt-4">
                  <h6 class="mb-2 font-semibold tex-lg capitalize">
                    {" "}
                    Add Answers options *
                  </h6>
                  <div>
                    {options.map((option, index) => (
                      <div
                        key={index}
                        className="flex items-center mt-3 w-full   h-12"
                      >
                        <div className="w-full">
                          <input
                            type="text"
                            id="title"
                            value={option.title}
                            onChange={(e) =>
                              handleChangeOptional(
                                index,
                                "title",
                                e.target.value
                              )
                            }
                            placeholder="add optional"
                            className="input input-bordered w-full "
                          />
                        </div>
                        <div className=" bg-gray-300 h-full px-3 rounded">
                          <input
                            type="checkbox"
                            checked={option.isCorrect}
                            onChange={() => handleCheckboxChange(index)}
                            className="h-full w-full px-2 py-2 bg-blue-500"
                          />
                        </div>
                        <div
                          onClick={() => handleRemoveTag(index)}
                          className="border p-2 rounded-lg hover:opacity-75 ml-2 cursol-pointer"
                        >
                          <IoMdRemove />
                        </div>
                      </div>
                    ))}
                    <button
                      type="button"
                      className="bg-gray-400 hover:bg-gray-300 rounded py-1 px-2  flex items-center text-sm mt-2 transaction duration-400"
                      onClick={() => handleAddTag()}
                    >
                      <div className="mr-1">
                        <IoMdAdd />
                      </div>
                      <div>Add </div>
                    </button>
                  </div>
                </div>
              </div>

              <div className="mt-6 flex justify-end">
                <button
                  className=" px-4 py-2 bg-gray-600 text-white rounded-md"
                  onClick={() => setIsOpen(false)}
                >
                  Close
                </button>
                <button
                  type="submit"
                  className="flex items-center ml-2 px-4 py-2 bg-blue-600 text-white rounded-md"
                  disabled={loading}
                >
                  {loading && (
                    <span className="loading loading-spinner loading-sm mr-2"></span>
                  )}{" "}
                  Save Changes
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};
