import React, { useState, useEffect, useRef } from "react";
import { FaPlus } from "react-icons/fa";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import QuillEditor from "../QuillEditor";
import axios from "../../helpers/axios";
import { useForm } from "react-hook-form";
import { createCourseSchema } from "../validations/courseSchema";
import { yupResolver } from "@hookform/resolvers/yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CreateAssignmentModal = ({ id, addAssignment }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [categoryIds, setCategoryIds] = useState([]);
  const [due_date, setDue_date] = useState(null);
  const handleQuillChange = (value) => {
    setDescription(value);
  };
  const today = new Date().toISOString().split("T")[0];

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    formData.append("due_date", due_date);
    try {
      await axios
        .post(`/courses/${id}/assignments/create/`, formData)
        .then((res) => {
          if (res.data) {
            console.log("res", res);
            setLoading(false);
            toast.success("Assignment created successfully!");
            setIsOpen(false);
            setTitle("");
            setDescription("");
            setDue_date(null);
            addAssignment(res.data);
          } else {
            setLoading(false);
            toast.error("Failed to create Assignment");
          }
        })
        .catch((error) => {
          setLoading(false);
          toast.error("Failed to create Assignment");
        });
    } catch (error) {
      setLoading(false);
      console.log("Error occurred while creating Assignment:", error);
    }
  };

  return (
    <div className="">
      <button
        className="px-4 py-2 bg-[#198754] text-white rounded-md flex items-center"
        onClick={() => setIsOpen(true)}
      >
        <div>
          <FaPlus />
        </div>
        <div className="ml-2">Add Assignment</div>
      </button>

      {isOpen && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-md shadow-md w-[90%] md:w-[50%] max-h-[95vh] overflow-y-auto ">
            <div className="flex justify-between items-center">
              <h2 className="text-2xl font-semibold">Add Assignment</h2>
              <button
                className="text-gray-600 hover:text-gray-900"
                onClick={() => setIsOpen(false)}
              >
                <svg
                  className="h-6 w-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <form onSubmit={handleSubmit} className="space-y-6">
              <div>
                <label
                  htmlFor="title"
                  className="block text-sm font-medium text-gray-700 mb-2"
                >
                  Title
                </label>
                <input
                  type="text"
                  id="title"
                  placeholder="Enter title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  required
                  className="input input-bordered w-full"
                />
              </div>
              <div className=" ">
                <label
                  htmlFor="description"
                  className="block text-sm font-medium text-gray-700 mb-2"
                >
                  Description
                </label>
                <QuillEditor value={description} onChange={handleQuillChange} />
              </div>

              <div>
                <label
                  htmlFor="due_date"
                  className="block text-sm font-medium text-gray-700 mb-2"
                >
                  Due date
                </label>
                <input
                  type="date"
                  id="due_date"
                  name="due_date"
                  min={today}
                  onChange={(e) => setDue_date(e.target.value)}
                  className="file-input file-input-bordered w-full"
                />
              </div>
              <div className="mt-6 flex justify-end">
                <button
                  className=" px-4 py-2 bg-gray-600 text-white rounded-md"
                  onClick={() => setIsOpen(false)}
                >
                  Close
                </button>
                <button
                  type="submit"
                  className="flex items-center ml-2 px-4 py-2 bg-blue-600 text-white rounded-md"
                  disabled={loading}
                >
                  {loading && (
                    <span className="loading loading-spinner loading-sm mr-2"></span>
                  )}{" "}
                  Add Assignment
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default CreateAssignmentModal;
