import { useState, useEffect, useRef } from "react";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { toast } from "react-toastify";
import axios from "../../helpers/axios";

const DeleteCourseModal = ({ id, removeCourse }) => {
  const [toastShown, setToastShown] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const hasToastShow = useRef(false);
  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);
  const handleDelete = async (e) => {
    e.preventDefault();
    setLoading(true);
    await axios
      .delete(`courses/${id}/`)
      .then((res) => {
        if (res) {
          closeModal();
          setLoading(false);
          removeCourse(id)
          toast.success("Course deleted successfully");
        } else {
          setLoading(false);
          toast.error("Failed to delete course");
        }
      })
      .catch(() => {
        setLoading(false);
        toast.error("Failed to delete course");
      });
  };

  useEffect(() => {
    if (isOpen) {
      hasToastShow.current = false;
    }
  }, [isOpen]);

  return (
    <>
      <button onClick={openModal} className="text-red-500 font-medium">
        <MdOutlineDeleteOutline size={24} />
      </button>
      {isOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg relative">
            <div className="text-red-500 font-medium text-3xl flex justify-center">
              <MdOutlineDeleteOutline />
            </div>
            <div className="mt-4 text-center">
              <h2 className="text-xl font-semibold">
                Are you sure you want to delete this item?
              </h2>
            </div>
            <div className="mt-6 flex justify-around">
              <button
                onClick={handleDelete}
                disabled={loading}
                className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 transition-colors"
              >
                {loading ? "loading..." : "Confirm"}
              </button>
              <button
                onClick={closeModal}
                className="bg-gray-300 text-gray-700 px-4 py-2 rounded hover:bg-gray-400 transition-colors"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DeleteCourseModal;
