import React, { useEffect, useState } from "react";
import { FaFileAlt } from "react-icons/fa";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "../helpers/axios";

export const ViewPdf = () => {
  const { courseId, lectureId } = useParams();
  const [courseLoading, setCourseLoading] = useState(false);
  const [course, setCourse] = useState([]);
  const [lecture, setLecture] = useState({
    title: "",
    pdf_url: ""
  });
  
  const navigate = useNavigate();
  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    if (!userInfo) {
      navigate("/login");
      return;
    }
  }, []);

//   console.log("id:", lectureId);
  useEffect(() => {
    setCourseLoading(true);
    axios
      .get(`/lectures/${lectureId}/`)
      .then((res) => {
        setCourseLoading(false);
        if (res.data) {
          setLecture(res.data);
        } else {
          // console.log("error happen");
        }
      })
      .catch((error) => {
        // console.error("Error:", error);
        setCourseLoading(false);
      });
  }, []);
//   console.log("lecture: ", lecture.title);

  return (
    <div className="container my-10">
      {courseLoading ? (
        <div class="flex justify-center w-[100%]">
              <span class="loading loading-bars loading-lg"></span>
            </div>
      ) : (
        <div>
          <div className="flex my-2 ">
            <div className="border rounded flex items-center justify-center w-20">
              <FaFileAlt size={25} />{" "}
            </div>
            <div className="px-3 py-1 my-2">
              <div className=" text-xl">File</div>
              <div>
                <h2>{lecture.title ? lecture.title : ''}</h2>
              </div>
            </div>
          </div>

          <div className="bg-gray-100 rounded-3xl p-10  pb-20 mt-3">
            <embed src={lecture?.pdf_url} width="100%" height="1000px" />
          </div>
        </div>
      )}
    </div>
  );
};