import React, { useState, useEffect, useRef } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { ToastContainer, toast } from "react-toastify";
import QuillEditor from "../../../QuillEditor";
import axios from "../../../../helpers/axios";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { createCourseSchema } from "../../../validations/courseSchema";
import DOMPurify from "dompurify";
import CreateQuestionModal from "../../../Actions/CreateQuestionModal";
import DeleteQuestionModal from "../../../Actions/DeleteQuestionModal";
import { AddQuestionOptional } from "../../../Actions/AddQuestionOptional";

const Quiz = () => {
  const navigate = useNavigate();
  const { courseId, quizId } = useParams();
  const [loading, setLoading] = useState(false);
  const [assignmentLoading, setAssignmentLoading] = useState(false);
  const [assignments, setAssignments] = useState(false);
  const [questions, setQuestions] = useState(false);
  const [questionsLoading, setQuestionsLoading] = useState(true);

  const initialState = {
    title: "",
    description: "",
  };
  const [quiz, setQuiz] = useState(initialState);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(createCourseSchema),
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setQuiz({ ...quiz, [name]: value });
  };

  const handleQuillChange = (value) => {
    setQuiz((prevState) => ({ ...prevState, description: value }));
  };

  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    if (!userInfo) {
      window.location.href = "/login";
      return;
    }
  }, []);

  //   getting one quiz
  useEffect(() => {
    setLoading(true);
    axios
      .get(`/quiz/${quizId}/`)
      .then((res) => {
        if (res.data) {
          setLoading(false);
          setQuiz({
            title: res.data.title,
            description: res.data.description,
          });
          setQuestions(res.data.questions);
        } else {
          setLoading(false);
          console.log("error happen");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setLoading(false);
      });
  }, []);

  // get assignments
  useEffect(() => {
    setAssignmentLoading(true);
    axios
      .get(`/assignments/`)
      .then((res) => {
        if (res.data) {
          setAssignmentLoading(false);
          setAssignments(res.data);
        } else {
          setAssignmentLoading(false);
          console.log("error happen");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setAssignmentLoading(false);
      });
  }, []);

  // get questions
  useEffect(() => {
    setQuestionsLoading(true);
    axios
      .get(`/questions/`)
      .then((res) => {
        if (res.data) {
          setQuestionsLoading(false);
        } else {
          setQuestionsLoading(false);
          console.log("error happen");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setQuestionsLoading(false);
      });
  }, []);

  const handleEditQuize = async (e) => {
    setLoading(true);
    e.preventDefault();
    const formData = new FormData();
    formData.append("title", quiz.title);
    formData.append("description", quiz.description);
    await axios
      .put(`/quiz/${quizId}/`, formData)
      .then((res) => {
        if (res.data) {
          setLoading(false);
          console.log("res", res);
          toast.success("Quize updated successfully");
        } else {
          setLoading(false);
          toast.error("something went wrong while updating Quize");
        }
      })
      .catch((error) => {
        toast.error("something went wrong while updating Quize");
      });
  };
  const addQuestion = (newQuestion) => {
    setQuestions((prevQuestion) => [...prevQuestion, newQuestion]);
  };
  // update question after edit question without refresh page
  const editQuestion = (updatedQuestion) => {
    setQuestions((prevQuestions) =>
      prevQuestions.map((question) =>
        question.id === updatedQuestion.id ? updatedQuestion : question
      )
    );
  };
  // update question after delete question without refresh page
  const removeQuestion = (questionId) => {
    setQuestions((prevQuestion) =>
      prevQuestion.filter((question) => question.id != questionId)
    );
  };

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className="container mx-auto my-5">
    <button onClick={goBack} className="btn bg-primary text-white">Go Back</button>
      <h4 className="text-center mb-5 font-bold">
        Quiz title: {loading ? "loading..." : quiz.title}
      </h4>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-8">
        <div className="border rounded-lg p-5">
          <ToastContainer />
          <h3>Edit Quiz</h3>
          <form className="space-y-6">
            <div className="mt-4">
              <div>
                <label
                  htmlFor="title"
                  className="block text-sm font-medium text-gray-700 my-2"
                >
                  Title
                </label>
                <input
                  type="text"
                  name="title"
                  id="title"
                  onChange={handleInputChange}
                  className="input input-bordered w-full"
                  value={loading ? "loading..." : quiz.title}
                />
              </div>
              <div className="my-2">
                <label
                  htmlFor="description"
                  className="block text-sm font-medium text-gray-700 my-2"
                >
                  Description
                </label>
                <QuillEditor
                  value={quiz.description}
                  onChange={handleQuillChange}
                />
              </div>
            </div>
            <div className="mt-6 flex justify-end">
              <button
                type="submit"
                className="flex items-center ml-2 px-4 py-2 bg-blue-600 text-white rounded-md"
                disabled={loading}
                onClick={handleEditQuize}
              >
                {loading && (
                  <span class="loading loading-spinner loading-sm mr-2"></span>
                )}{" "}
                Edit Quiz
              </button>
            </div>
          </form>
        </div>
        <div className="grid grid-cols1 gap-y-4 md:gap-y-8">
          <div className="border rounded-lg p-5">
            <div className="flex items-center justify-between ">
              <h4>Questions</h4>
              <div>
                <CreateQuestionModal
                  quizId={quizId}
                  addQuestion={addQuestion}
                />
              </div>
            </div>
            <div>
              {questionsLoading ? (
                <div>loading...</div>
              ) : (
                <div>
                  {questions.length ? (
                    <div className="my-3">
                      <table className="min-w-full bg-white border border-gray-200 rounded-lg shadow-md">
                        <thead>
                          <th className="py-3 px-6 bg-gray-100 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                            #
                          </th>
                          <th className="py-3 px-6 bg-gray-100 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                            question
                          </th>
                          <th className="py-3 px-6 bg-gray-100 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">
                            action
                          </th>
                        </thead>
                        <tbody>
                          {questions.map((question, index) => (
                            <tr key={index} className="bg-gray-50 border-b">
                              <td className="py-2 px-3 text-sm text-gray-500">
                                {index + 1}
                              </td>
                              <td className="py-2 px-3 text-sm text-gray-500">
                                <p
                                  className="card-text truncate-text"
                                  dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(question.text),
                                  }}
                                />
                              </td>
                              <td className="flex items-center py-4 px-6 text-sm text-gray-500">
                                <div className="">
                                  <AddQuestionOptional
                                    question={question}
                                    editQuestion={editQuestion}
                                  />
                                </div>
                                <div>
                                  <DeleteQuestionModal
                                    id={question.id}
                                    removeQuestion={removeQuestion}
                                  />
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <div>No Quizzes found</div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Quiz;
